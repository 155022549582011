import { DeliveryType } from '../../enums/DeliveryType';
import { ItemType } from '../../enums/ItemType';
import { ProfitabilityAnalysisStatus } from '../../enums/ProfitabilityAnalysisStatus';
import { SaleOrderSimulationQueryParams } from '../../interfaces/SaleOrderSimulationQueryParams';

export interface ISalePriceFormationAnalysis {
  id?: string;
  nrOrder?: string;
  revision?: string;
  totalDirectCost?: number;
  priceListStateSalePriceFormation?: IPriceListStateSalePriceFormationAnalysis;
  totalIndirectCost?: number;
  baseCommission?: number;
  salePrice?: number;
  formula?: string;
  costBaseMonth?: string;
  priceList?: string;
  averageTerm?: number;
  indirectCostSalePriceFormations?: IIndirectCostSalePriceFormationAnalysis[];
  averagePaymentTermSalePriceFormations?: IAveragePaymentTermSalePriceFormationAnalysis[];
  rangeCommissionSalePriceFormations?: IRangeCommissionSalePriceFormationAnalysis[];
  indexValue?: string;
  indexValues?: { [key: string]: string };
  discountIndex?: number
  totalIndirectCostSimulation?: number;
  totalSPPVIndex?: number;
  totalUnitValueIndirectCost?: number;
  formulas?: IFormula[];
  grossProfit?: number;
  netProfit?: number;
  unitValueAverageTerm?: number;
  unitValueProfit?: number;
  unitValueProfitSimulation?: number;
  totalUnitValueIndirectCostSimulation?: number;
  unitValueRangeCommission?: number;
  indexValueRangeCommissionSimulation?: number;
  unitValueRangeCommissionSimulation?: number;
  grossMargin?: number;
  grossProfitSaleOrder?: number;
  netProfitSaleOrder?: number;
  netProfitSimulation?: number;
  netProfitMargin?: number;
  netProfitMarginSaleOrder?: number;
  netProfitMarginSimulation?: number;
  finantialCost?: number;
}

export interface IFormula {
  id: string;
  description: string;
}

export interface IRangeCommissionSalePriceFormationAnalysis {
  rangeCommissionId: string,
  salePriceFormationId: string,
  selectedRangeSimulation: boolean,
  commissionRate: number;
  rangeCommission: {
    range: number;
  };
  sppvIndex?: number;
  sppvUnitValueR?: number;
  unitValueR?: number;
}

export interface IIndirectCostSalePriceFormationAnalysis {
  indirectCostId: string;
  salePriceFormationId: string;
  interestRate: number;
  interestRateSimulation: number;
  indirectCost: {
    code: string;
    description: string;
  };
  unitValueI?: number;
  sppvUnitValueI?: number;
}

export interface IAveragePaymentTermSalePriceFormationAnalysis {
  interestRate: number;
  averagePaymentTerm: {
    code: number;
    description: string;
  };
  withDiscount?: boolean;
  unitValueFPVpmV: number;
}

export interface IPriceListStateSalePriceFormationAnalysis {
  id: string;
  priceListStateId: string;
  salePriceFormationId: string;
  discount: number;
  priceListState: {
    priceList: string;
  };
}

export interface ISaleOrderSimulationItem {
  id: string;
  codeDescription?: string;
  nrItem: number;
  price: number;
  quantity: number;
  total: number;
  discount: number;
  discountValue: number;
  totalWithDiscount: number;
  commission: number;
  commissionValue: number;
  type: ItemType;
  salePriceFormation?: ISalePriceFormationAnalysis;
  selected?: boolean;
  grossMargin?: number;
  grossProfitSaleOrder?: number;
  grossProfitSimulation?: number;
  netProfitMargin?: number;
  netProfitSaleOrder?: number;
  netProfitSimulation?: number;
  netProfitMarginSaleOrder?: number;
  netProfitMarginSimulation?: number;
}

export interface ISaleOrderSimulation {
  id: string;
  companyId: string;
  nrOrder: string;
  revision: string;
  emissionDate: Date;
  salesman: string;
  client: string;
  locality: string;
  deliveryType: DeliveryType;
  priceList: string;
  paymentTerm: string;
  averagePaymentTerm: string;
  status: ProfitabilityAnalysisStatus;
  saleForceOrder: string;
  saleOrderSimulationItems: ISaleOrderSimulationItem[];
  branchId?: string;
}

export interface ISaleOrderSimulations {
  data: ISaleOrderSimulation[];
  count: number;
}

export const initialSaleOrderSimulationItem: ISaleOrderSimulationItem = {
	id: '',
	codeDescription: '',
	nrItem: 0,
	price: 0,
	quantity: 0,
	total: 0,
	discount: 0,
	discountValue: 0,
	totalWithDiscount: 0,
	commission: 0,
	commissionValue: 0,
	type: ItemType.SALE,
	salePriceFormation: {
		nrOrder: '',
		revision: '',
		totalDirectCost: 0,
		totalIndirectCost: 0,
		baseCommission: 0,
		salePrice: 0,
		formula: '',
		costBaseMonth: '',
		priceList: '',
		averageTerm: 0,
		indexValue: '0',
		discountIndex: 0,
		totalIndirectCostSimulation: 0,
		totalSPPVIndex: 0,
		totalUnitValueIndirectCost: 0,
		grossProfit: 0,
		netProfit: 0,
		priceListStateSalePriceFormation: {
			id: '',
			priceListStateId: '',
			salePriceFormationId: '',
			discount: 0,
			priceListState: {
				priceList: '',
			},
		},
		indirectCostSalePriceFormations: [
			{
				indirectCostId: '',
				salePriceFormationId: '',
				interestRate: 0,
				interestRateSimulation: 0,
				unitValueI: 0,
				sppvUnitValueI: 0,
				indirectCost: {
					code: '',
					description: '',
				},
			},
		],
		rangeCommissionSalePriceFormations: [
			{
				rangeCommissionId: '',
				salePriceFormationId: '',
				selectedRangeSimulation: false,
				commissionRate: 0,
				rangeCommission: {
					range: 0,
				},
				sppvIndex: 0,
				sppvUnitValueR: 0,
				unitValueR: 0,
			},
		],
	},
	grossProfitSaleOrder: 0,
	grossProfitSimulation: 0,
	netProfitSaleOrder: 0,
	netProfitSimulation: 0,
	netProfitMarginSaleOrder: 0,
	netProfitMarginSimulation: 0,
};

export const initialSaleOrderSimulation: ISaleOrderSimulation = {
	id: '',
	companyId: '',
	nrOrder: '',
	revision: '',
	emissionDate: new Date(),
	salesman: '',
	client: '',
	locality: '',
	deliveryType: DeliveryType.CIF,
	priceList: '',
	paymentTerm: '',
	averagePaymentTerm: '',
	status: ProfitabilityAnalysisStatus.PENDING,
	saleForceOrder: '',
	saleOrderSimulationItems: [initialSaleOrderSimulationItem],
	branchId: undefined,
};

export const initialSalePriceFormationAnalysis: ISalePriceFormationAnalysis = {
	nrOrder: '',
	revision: '',
	totalDirectCost: 0,
	totalIndirectCost: 0,
	baseCommission: 0,
	salePrice: 0,
	formula: '',
	costBaseMonth: '',
	priceList: '',
	averageTerm: 0,
	indexValue: '0',
	discountIndex: 0,
	totalIndirectCostSimulation: 0,
	totalSPPVIndex: 0,
	totalUnitValueIndirectCost: 0,
	grossProfit: 0,
	netProfit: 0,
	priceListStateSalePriceFormation: {
		id: '',
		priceListStateId: '',
		salePriceFormationId: '',
		discount: 0,
		priceListState: {
			priceList: '',
		},
	},
	indirectCostSalePriceFormations: [
		{
			indirectCostId: '',
			salePriceFormationId: '',
			interestRate: 0,
			interestRateSimulation: 0,
			unitValueI: 0,
			sppvUnitValueI: 0,
			indirectCost: {
				code: '',
				description: '',
			},
		},
	],
	rangeCommissionSalePriceFormations: [
		{
			rangeCommissionId: '',
			salePriceFormationId: '',
			selectedRangeSimulation: false,
			commissionRate: 0,
			rangeCommission: {
				range: 0,
			},
			sppvIndex: 0,
			sppvUnitValueR: 0,
			unitValueR: 0,
		},
	],
};

export const initialValuesFilter: SaleOrderSimulationQueryParams = {
	skip: 0,
};
