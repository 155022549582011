/* eslint-disable react/jsx-props-no-spreading */
import React, {
	Dispatch,
	Reducer,
	useMemo,
	useReducer,
} from 'react';
import {
	OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import {
	createInventoryTask,
	finishInventory,
	finishInventoryTask,
	getInventoryItemsById,
	postRecount,
	startInventoryTask,
} from '../../services/inventory';
import InventoryTaskPresentational from '../../components/Inventory/InventoryTasks';
import { LocationProductQueryParams } from '../../interfaces/LocationProduct';
import { ILocationWithPagination } from '../Location/LocationAssets';
import { IInventoryInfo, IInventoryItems, InventoryTaskPostParams } from './InventoryAssets';
import { getLocations } from '../../services/location';
import {
	InventoryItemsByIdParams,
	ParamsFinishInventoryTask,
	ParamsStartInventoryTask,
	RecountParams,
} from '../../interfaces/InventoryParams';
import { IProduct } from '../Product/ProductAssets';
import { ProductQueryParams } from '../../interfaces/ProductQueryParams';
import { getProducts } from '../../services/product';
import { User } from '../User/UserAssets';
import { UserQueryParams } from '../../interfaces/UserQueryParams';
import { getUsers } from '../../services/user';
import { InventoryStatus } from '../../enums/inventory';

enum ActionType {
	LOADING,
	INVENTORY_ITEM,
	LOCATION,
	PRODUCT,
	USERS,
	MODAL_CREATE_TASK
}

interface IState {
	loading: boolean;
	inventoryItems: IInventoryItems[];
	count: number;
	users: User[];
	products: IProduct[],
	inventoryItemsCount: number;
	inventoryItemsPage: number;
	inventoryItemsTake: number;
	openModalCreateTask: boolean;
	locations: ILocationWithPagination;
	inventory: IInventoryInfo;
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.MODAL_CREATE_TASK; payload: { openModalCreateTask: boolean } }
	| { type: ActionType.LOCATION; payload: { locations: ILocationWithPagination } }
	| { type: ActionType.PRODUCT; payload: { products: IProduct[] } }
	| {
		type: ActionType.USERS; payload: {
			users: User[],
		}
	}
	| { type: ActionType.INVENTORY_ITEM;
		payload: {
			inventoryItems: IInventoryItems[];
			inventoryItemsCount: number;
			inventoryItemsPage: number;
			inventoryItemsTake: number;
			inventory: IInventoryInfo;
		}}

interface IInventoryTaskActions {
	setLoading(loading: boolean): void;
	setCreateModalOpen(openModalCreateTask: boolean): void;
	getInventoryItemsById(id: string, queryParams?: InventoryItemsByIdParams): void;
	getLocations: (queryParams?: LocationProductQueryParams) => void;
	getProducts: (queryParams?: ProductQueryParams) => void;
	finishInventoryTask: (data: ParamsFinishInventoryTask) => void;
	startInventoryTask: (data?: ParamsStartInventoryTask) => void;
	getUsers(queryParams: UserQueryParams): void;
	createInventoryTask: (data: InventoryTaskPostParams) => void;
	postRecount(params: RecountParams): void;
	finishInventory(inventoryId: string): void;
}

const initialState: IState = {
	loading: false,
	inventoryItems: [],
	users: [],
	openModalCreateTask: false,
	count: 0,
	inventoryItemsCount: 0,
	inventoryItemsPage: 0,
	inventoryItemsTake: 10,
	locations: { count: 0, locations: [] },
	products: [],
	inventory: {
		countNumber: 0,
		date: '',
		document: '',
		haveItemsForNextCount: false,
		responsibleName: '',
		status: InventoryStatus.PENDING,
		startCount: false,
	},
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.USERS:
			return {
				...state,
				users: action.payload.users,
			};
		case ActionType.MODAL_CREATE_TASK:
			return { ...state, openModalCreateTask: action.payload.openModalCreateTask };
		case ActionType.INVENTORY_ITEM:
			return {
				...state,
				inventoryItems: action.payload.inventoryItems,
				inventoryItemsCount: action.payload.inventoryItemsCount,
				inventoryItemsTake: action.payload.inventoryItemsTake,
				inventoryItemsPage: action.payload.inventoryItemsPage,
				inventory: action.payload.inventory,
			};
		case ActionType.LOCATION:
			return {
				...state,
				locations: action.payload.locations,
			};
		case ActionType.PRODUCT:
			return { ...state, products: action.payload.products };
		default:
			throw new Error();
	}
};

const InventoryTaskActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
	id?: string,
): IInventoryTaskActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getUsers(queryParams: UserQueryParams) {
			actions.setLoading(true);

			getUsers(queryParams).then((response) => {
				dispatch({
					type: ActionType.USERS,
					payload: {
						users: response.data.data,
					},
				});
				actions.setLoading(false);
			});
		},
		setCreateModalOpen(openModalCreateTask: boolean) {
			dispatch({ type: ActionType.MODAL_CREATE_TASK, payload: { openModalCreateTask } });
		},
		postRecount(params: RecountParams) {
			actions.setLoading(true);
			postRecount(params).then((response) => {
				enqueueSnackbar(response.data.message, { variant: 'success' });
				if (id) {
					actions.getInventoryItemsById(id, { skip: 0 });
				}
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
			}).finally(() => {
				actions.setLoading(false);
			});
		},
		async getProducts(queryParams?: ProductQueryParams) {
			actions.setLoading(true);
			try {
				const response = await getProducts(queryParams);
				dispatch({
					type: ActionType.PRODUCT,
					payload: {
						products: response.data.data,
					},
				});
			} catch (error) {
				const axiosError = error as AxiosError;
				enqueueSnackbar(
					axiosError.response?.data.message
			|| 'Algum erro ocorreu, tente novamente ou contate um administrador.',
					{ variant: 'error' },
				);
			} finally {
				actions.setLoading(false);
			}
		},
		getInventoryItemsById(inventoryId: string, queryParams: InventoryItemsByIdParams) {
			actions.setLoading(true);
			const take = queryParams.take ?? 10;
			const skip = queryParams.skip ?? 0;
			const params = {
				...queryParams,
				skip: skip * take,
				take: queryParams.take ?? 10,
			};
			getInventoryItemsById(inventoryId, params).then((response) => {
				dispatch({
					type: ActionType.INVENTORY_ITEM,
					payload: {
						inventoryItems: response.data.inventoryItems,
						inventoryItemsCount: response.data.count,
						inventoryItemsPage: queryParams.skip ?? 0,
						inventoryItemsTake: take,
						inventory: response.data.inventory,
					},
				});
				actions.setLoading(false);
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
			});
		},
		createInventoryTask(params: InventoryTaskPostParams) {
			actions.setLoading(true);

			createInventoryTask(params).then((response) => {
				enqueueSnackbar(
					response.data.message || 'Tarefa do Inventário criada com sucesso!',
					{ variant: 'success' },
				);
				actions.setCreateModalOpen(false);
				if (id) {
					actions.getInventoryItemsById(id, { skip: 0 });
				}
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
			}).finally(() => {
				actions.setLoading(false);
			});
		},
		finishInventory(inventoryId: string) {
			actions.setLoading(true);

			finishInventory(inventoryId).then((response) => {
				enqueueSnackbar(
					response.data.message || 'Tarefa do Inventário criada com sucesso!',
					{ variant: 'success' },
				);
				actions.setCreateModalOpen(false);
				if (id) {
					actions.getInventoryItemsById(id, { skip: 0 });
				}
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
			}).finally(() => {
				actions.setLoading(false);
			});
		},
		startInventoryTask(params?: ParamsStartInventoryTask) {
			actions.setLoading(true);

			const taskPromise = params ? startInventoryTask(params) : startInventoryTask();

			taskPromise
				.then((response) => {
					enqueueSnackbar(
						response.data.message || 'Tarefa do Inventário criada com sucesso!',
						{ variant: 'success' },
					);
					if (id) {
						actions.getInventoryItemsById(id, { skip: 0 });
					}
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
						{ variant: 'error' },
					);
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		finishInventoryTask(params: ParamsFinishInventoryTask) {
			actions.setLoading(true);

			finishInventoryTask(params).then((response) => {
				enqueueSnackbar(
					response.data.message || 'Tarefa do Inventário criada com sucesso!',
					{ variant: 'success' },
				);
				if (id) {
					actions.getInventoryItemsById(id, { skip: 0 });
				}
			}).catch((error: AxiosError) => {
				enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
					variant: 'error',
				});
			}).finally(() => {
				actions.setLoading(false);
			});
		},
		getLocations(queryParams?: LocationProductQueryParams) {
			getLocations(queryParams)
				.then((response) => {
					dispatch({
						type: ActionType.LOCATION,
						payload: {
							locations: response.data,
						},
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
						{ variant: 'error' },
					);
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
	};

	return actions;
};

const InventoryTasks = ():JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(reducer, initialState);
	const { enqueueSnackbar } = useSnackbar();
	const { id } = useParams<{ id: string }>();

	const actions = useMemo(
		() => InventoryTaskActions(dispatch, enqueueSnackbar, id),
		[enqueueSnackbar, id],
	);
	return (
		<InventoryTaskPresentational
			{...state}
			{...actions}
		/>
	);
};

export default InventoryTasks;
