import React, { useMemo, useState } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	Paper,
	Box,
	Tooltip,
	IconButton,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CalculateIcon from '@mui/icons-material/Calculate';
import { useFormikContext } from 'formik';
import {
	initialSaleOrderSimulationItem,
	initialSalePriceFormationAnalysis,
	ISaleOrderSimulation,
} from '../../../containers/ProfitabilityAnalysis/ProfitabilityAnalysisAssets';
import { currencyBRLMask } from '../../../helpers/intl';
import Input from '../../Common/Form/Input';
import {
	calculateGrossMargin,
	calculateGrossProfit,
	calculateGrossProfitSaleOrder,
	calculateNetProfit,
	calculateNetProfitMargin,
	calculateNetProfitMarginSaleOrder,
	calculateNetProfitMarginSimulation,
	calculateNetProfitSaleOrder,
	calculateNetProfitSimulation,
	getFinancialCost,
	getSalePriceFormation,
	getSelectedItem,
	getSelectedItemIndex,
	getTotalUnitValueIndirectCost,
	getTotalUnitValueIndirectCostSimulation,
	getUnitValueProfit,
	getUnitValueProfitSimulation,
} from '../../../helpers/ProfitabilityAnalysisCalculations';
import { useFormikValuesUpdater } from '../../../hooks/useFormikValuesUpdater';

const customInputProps = {
	sx: {
		height: '25px',
		padding: 0,
	},
	style: {
		paddingLeft: '0px',
		margin: 0,
		lineHeight: 'normal',
	},
	inputProps: {
		style: {
			padding: '0 5px',
			height: '25px',
			color: 'gray',
		},
		readOnly: true,
	},
};

const SalePriceFormationSimulationTable = (): JSX.Element => {
	const { values } = useFormikContext<ISaleOrderSimulation>();
	const [showExtraColumns, setShowExtraColumns] = useState(true);

	const selectedItem = useMemo(
		() => getSelectedItem(values.saleOrderSimulationItems, initialSaleOrderSimulationItem),
		[values.saleOrderSimulationItems],
	);

	const selectedItemIndex = useMemo(
		() => getSelectedItemIndex(values.saleOrderSimulationItems),
		[values.saleOrderSimulationItems],
	);

	const salePriceFormation = useMemo(
		() => getSalePriceFormation(selectedItem, initialSalePriceFormationAnalysis),
		[selectedItem],
	);

	const grossMargin = calculateGrossMargin(salePriceFormation);
	const grossProfit = calculateGrossProfit(selectedItem);
	const grossProfitSaleOrder = calculateGrossProfitSaleOrder(selectedItem);
	const grossProfitSimulation = calculateGrossProfitSaleOrder(selectedItem);

	const totalUnitValueIndirectCost = getTotalUnitValueIndirectCost(selectedItem);
	const totalUnitValueIndirectCostSimulation = getTotalUnitValueIndirectCostSimulation(
		selectedItem,
	);

	const unitValueProfit = getUnitValueProfit(selectedItem);
	const unitValueProfitSimulation = getUnitValueProfitSimulation(selectedItem);

	const netProfit = calculateNetProfit(
		grossProfit,
		totalUnitValueIndirectCost,
		unitValueProfit,
		selectedItem,
	);

	const netProfitSaleOrder = calculateNetProfitSaleOrder(
		grossProfitSaleOrder,
		totalUnitValueIndirectCost,
		unitValueProfit,
		selectedItem,
	);

	const netProfitSimulation = calculateNetProfitSimulation(
		grossProfitSaleOrder,
		totalUnitValueIndirectCostSimulation,
		unitValueProfitSimulation,
		selectedItem,
	);

	const netProfitMargin = calculateNetProfitMargin(
		netProfit,
		salePriceFormation,
	);
	const netProfitMarginSaleOrder = calculateNetProfitMarginSaleOrder(
		netProfitSaleOrder,
		selectedItem,
	);
	const netProfitMarginSimulation = calculateNetProfitMarginSimulation(
		netProfitSimulation,
		selectedItem,
	);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const financialCost = useMemo(() => getFinancialCost(selectedItem), [
		selectedItem.salePriceFormation?.unitValueAverageTerm]);

	useFormikValuesUpdater(
		{
			[`saleOrderSimulationItems[${selectedItemIndex}].grossMargin`]:
				grossMargin,
			[`saleOrderSimulationItems[${selectedItemIndex}].netProfitMargin`]:
				netProfitMargin,
			[`saleOrderSimulationItems[${selectedItemIndex}].grossProfitSaleOrder`]:
				grossProfitSaleOrder,
			[`saleOrderSimulationItems[${selectedItemIndex}].grossProfitSimulation`]:
				grossProfitSimulation,
			[`saleOrderSimulationItems[${selectedItemIndex}].netProfitSaleOrder`]:
				netProfitSaleOrder,
			[`saleOrderSimulationItems[${selectedItemIndex}].netProfitSimulation`]:
				netProfitSimulation,
			[`saleOrderSimulationItems[${selectedItemIndex}].netProfitMarginSaleOrder`]:
				netProfitMarginSaleOrder,
			[`saleOrderSimulationItems[${selectedItemIndex}].netProfitMarginSimulation`]:
				netProfitMarginSimulation,
		},
		[
			grossMargin,
			netProfitMargin,
			grossProfitSaleOrder,
			grossProfitSimulation,
			netProfitSaleOrder,
			netProfitSimulation,
			netProfitMarginSaleOrder,
			netProfitMarginSimulation,
		],
	);

	// const handleCalculateFormula = useCallback(
	// 	(formulaAlias: string, formulaDefault: string) => {
	// 		if (!salePriceFormation) {
	// 			enqueueSnackbar(
	// 				'Fórmula não definida ou item não selecionado',
	// 				{ variant: 'error' },
	// 			);
	// 			return;
	// 		}

	// 		// Verifica se a fórmula está no array de fórmulas
	// 		const formulaFromArray = salePriceFormation?.formulas?.find(
	// 			(f: any) => f.id === formulaAlias,
	// 		)?.description;

	// 		// Validação: se o campo formula está vazio, exibe um erro
	// 		if (!formulaFromArray && !formulaDefault) {
	// 			enqueueSnackbar('Fórmula não disponível para cálculo', {
	// 				variant: 'error',
	// 			});
	// 			return;
	// 		}

	// 		// Usa a fórmula do array, ou a fórmula padrão caso não exista no array
	// 		const formulaToUse = formulaFromArray || formulaDefault;

	// 		if (!formulaFromArray || formulaFromArray.trim() === '') {
	// 			enqueueSnackbar(
	// 				'O campo de fórmula está vazio. Por favor, insira uma fórmula.',
	// 				{ variant: 'error' },
	// 			);
	// 			return;
	// 		}

	// 		// Substituição dos aliases na fórmula
	// 		const processedFormula = replaceAliasesInFormula(
	// 			formulaToUse,
	// 			salePriceFormation,
	// 		);

	// 		try {
	// 			// Verifica se a fórmula está correta antes de executar
	// 			console.log('Processed formula:', processedFormula);

	// 			// eslint-disable-next-line no-eval
	// 			const result = eval(processedFormula);
	// 			console.log('Resultado do cálculo:', result);

	// 			// Atualiza o valor no Formik
	// 			const updatedSalePriceFormation = {
	// 				...salePriceFormation,
	// 				[aliasesSalePriceFormationSimulation[formulaAlias]]: result,
	// 			};

	// 			// Atualiza a lista de itens no estado do Formik
	// 			const updatedItems = [...values.saleOrderSimulationItems];
	// 			const selectedIndex = updatedItems.findIndex(
	// 				(item) => item.selected,
	// 			);

	// 			if (selectedIndex !== -1) {
	// 				updatedItems[selectedIndex] = {
	// 					...selectedItem,
	// 					salePriceFormation: updatedSalePriceFormation,
	// 				};

	// 				console.log('Updated items:', updatedItems);

	// 				// Atualiza o Formik com os novos valores
	// 				setFieldValue('saleOrderSimulationItems', updatedItems);
	// 				enqueueSnackbar('Fórmula calculada com sucesso', { variant: 'success' });
	// 			}
	// 		} catch (error) {
	// 			// Lida com erros de cálculo
	// 			console.error('Erro ao calcular a fórmula:', error);
	// 			enqueueSnackbar('Erro ao calcular a fórmula', {
	// 				variant: 'error',
	// 			});
	// 		}
	// 	},
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// 	[],
	// );

	const backgroundColor = useMemo(
		() => (showExtraColumns ? 'primary.dark' : 'primary.main'),
		[showExtraColumns],
	);

	if (!selectedItem || !selectedItem.salePriceFormation) {
		return (
			<Typography variant="body2">
				Selecione um item para visualizar a simulação.
			</Typography>
		);
	}

	console.log(selectedItem);

	return (
		<Box sx={{ display: 'flex', position: 'relative' }}>
			<TableContainer component={Paper} sx={{ flexGrow: 1 }}>
				<Table size="small" stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell sx={{ width: '25%' }}>
								<strong>Descrição (FPV)</strong>
							</TableCell>
							<TableCell sx={{ width: '10%' }} align="right">
								<strong>Valores</strong>
							</TableCell>
							<TableCell sx={{ width: '25%' }}>
								<strong>Fórmula</strong>
							</TableCell>
							{showExtraColumns && (
								<>
									<TableCell
										align="right"
										sx={{
											width: '20%',
											backgroundColor: '#f0f0f0',
										}}
									>
										<strong>Pedido Venda (PV)</strong>
									</TableCell>
									<TableCell
										align="right"
										sx={{
											width: '20%',
											backgroundColor: '#f0f0f0',
										}}
									>
										<strong>
											Simulação Preço PV (SPPV)
										</strong>
									</TableCell>
								</>
							)}
							<TableCell
								sx={{
									width: '0.001%',
									backgroundColor,
									padding: 0,
									textAlign: 'center',
								}}
							>
								<Tooltip
									title={
										showExtraColumns
											? 'Ocultar colunas de simulação'
											: 'Visualizar colunas de simulação'
									}
								>
									<IconButton
										sx={{ color: 'white' }}
										onClick={() => setShowExtraColumns(
											!showExtraColumns,
										)}
									>
										{showExtraColumns ? (
											<VisibilityOffIcon />
										) : (
											<VisibilityIcon />
										)}
									</IconButton>
								</Tooltip>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>Preço Venda (FPV.pv)</TableCell>
							<TableCell align="right">
								{currencyBRLMask(
									salePriceFormation?.salePrice || 0,
									{ decimalPlaces: 3 },
								)}
							</TableCell>
							<TableCell sx={{ color: 'gray' }}>{salePriceFormation?.formula}</TableCell>
							{showExtraColumns && (
								<>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									>
										{currencyBRLMask(selectedItem.price, {
											decimalPlaces: 3,
										})}
									</TableCell>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									>
										{currencyBRLMask(selectedItem.price, {
											decimalPlaces: 3,
										})}
									</TableCell>
								</>
							)}
							<TableCell
								sx={{
									width: '0.001%',
									backgroundColor,
									padding: 0,
									textAlign: 'center',
								}}
							/>
						</TableRow>

						<TableRow>
							<TableCell>Margem Bruta (FPV.mb)</TableCell>
							<TableCell align="right">
								{`${grossMargin
									.toFixed(3)
									.replace('.', ',')} %`}
							</TableCell>
							<TableCell>
								<Input.InputField
									name={`saleOrderSimulationItems[${values.saleOrderSimulationItems.indexOf(
										selectedItem,
									)}].salePriceFormation.formulas[0].description`}
									fullWidth
									InputProps={customInputProps}
								/>
							</TableCell>
							{showExtraColumns && (
								<>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									>
										{`${(
											((selectedItem.price
												- (salePriceFormation?.totalDirectCost
													|| 0))
												/ selectedItem.price)
											* 100
										)
											.toFixed(3)
											.replace('.', ',')} %`}
									</TableCell>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									>
										{`${(
											(1
												- (salePriceFormation?.totalDirectCost
													|| 0)
													/ selectedItem.price)
											* 100
										)
											.toFixed(3)
											.replace('.', ',')} %`}
									</TableCell>
								</>
							)}
							<TableCell
								sx={{
									width: '0.001%',
									backgroundColor,
									padding: 0,
									textAlign: 'center',
								}}
							>
								<Tooltip title="Calcular Margem Bruta">
									<IconButton>
										<CalculateIcon
											sx={{ color: 'white' }}
										/>
									</IconButton>
								</Tooltip>
							</TableCell>
						</TableRow>

						<TableRow>
							<TableCell>Lucro Bruto (FPV.lb)</TableCell>
							<TableCell align="right">
								{currencyBRLMask(
									grossProfit,
									{ decimalPlaces: 3 },
								)}
							</TableCell>
							<TableCell>
								<Input.InputField
									name={`saleOrderSimulationItems[${values.saleOrderSimulationItems.indexOf(
										selectedItem,
									)}].salePriceFormation.formulas[1].description`}
									fullWidth
									InputProps={customInputProps}
								/>
							</TableCell>
							{showExtraColumns && (
								<>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									>
										{currencyBRLMask(
											selectedItem.grossProfitSaleOrder
												|| 0,
											{ decimalPlaces: 3 },
										)}
									</TableCell>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									>
										{currencyBRLMask(
											selectedItem.grossProfitSimulation || 0,
											{ decimalPlaces: 3 },
										)}
									</TableCell>
								</>
							)}
							<TableCell
								sx={{
									width: '0.001%',
									backgroundColor,
									padding: 0,
									textAlign: 'center',
								}}
							>
								<Tooltip title="Calcular Lucro Bruto">
									<IconButton>
										<CalculateIcon
											sx={{ color: 'white' }}
										/>
									</IconButton>
								</Tooltip>
							</TableCell>
						</TableRow>

						<TableRow>
							<TableCell>Lucro Líquido (FPV.ll)</TableCell>
							<TableCell align="right">
								{currencyBRLMask(
									netProfit,
									{ decimalPlaces: 3 },
								)}
							</TableCell>
							<TableCell>
								<Input.InputField
									name={`saleOrderSimulationItems[${values.saleOrderSimulationItems.indexOf(
										selectedItem,
									)}].salePriceFormation.formulas[2].description`}
									fullWidth
									InputProps={customInputProps}
								/>
							</TableCell>
							{showExtraColumns && (
								<>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									>
										{currencyBRLMask(
											selectedItem.netProfitSaleOrder
												|| 0,
											{ decimalPlaces: 3 },
										)}
									</TableCell>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									>
										{currencyBRLMask(
											selectedItem.netProfitSimulation
												|| 0,
											{ decimalPlaces: 3 },
										)}
									</TableCell>
								</>
							)}
							<TableCell
								sx={{
									width: '0.001%',
									backgroundColor,
									padding: 0,
									textAlign: 'center',
								}}
							>
								<Tooltip title="Calcular Lucro Líquido">
									<IconButton>
										<CalculateIcon
											sx={{ color: 'white' }}
										/>
									</IconButton>
								</Tooltip>
							</TableCell>
						</TableRow>

						<TableRow>
							<TableCell>
								Margem Lucro Líquido (FPV.mll)
							</TableCell>
							<TableCell align="right">
								{`${netProfitMargin
									.toFixed(3)
									.replace('.', ',')} %`}
							</TableCell>
							<TableCell>
								<Input.InputField
									name={`saleOrderSimulationItems[${values.saleOrderSimulationItems.indexOf(
										selectedItem,
									)}].salePriceFormation.formulas[3].description`}
									fullWidth
									InputProps={customInputProps}
								/>
							</TableCell>
							{showExtraColumns && (
								<>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									>
										{`${(
											selectedItem.netProfitMarginSaleOrder
											|| 0
										)
											.toFixed(3)
											.replace('.', ',')} %`}
									</TableCell>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									>
										{`${(
											selectedItem.netProfitMarginSimulation
											|| 0
										)
											.toFixed(3)
											.replace('.', ',')} %`}
									</TableCell>
								</>
							)}
							<TableCell
								sx={{
									width: '0.001%',
									backgroundColor,
									padding: 0,
									textAlign: 'center',
								}}
							>
								<Tooltip title="Calcular Margem Lucro Líquido">
									<IconButton>
										<CalculateIcon
											sx={{ color: 'white' }}
										/>
									</IconButton>
								</Tooltip>
							</TableCell>
						</TableRow>

						<TableRow>
							<TableCell>Custos Diretos (FPV.cd)</TableCell>
							<TableCell align="right">
								{currencyBRLMask(
									salePriceFormation?.totalDirectCost ?? 0,
									{ decimalPlaces: 3 },
								)}
							</TableCell>
							<TableCell />
							{showExtraColumns && (
								<>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
								</>
							)}
							<TableCell
								sx={{
									width: '0.001%',
									backgroundColor,
									padding: 0,
									textAlign: 'center',
								}}
							/>
						</TableRow>

						<TableRow>
							<TableCell>Custo Financeiro (FPV.cf)</TableCell>
							<TableCell align="right">
								{currencyBRLMask(financialCost, {
									decimalPlaces: 3,
								})}
							</TableCell>
							<TableCell />
							{showExtraColumns && (
								<>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
								</>
							)}
							<TableCell
								sx={{
									width: '0.001%',
									backgroundColor,
									padding: 0,
									textAlign: 'center',
								}}
							/>
						</TableRow>

						<TableRow>
							<TableCell>Custo Indireto (FPV.ci)</TableCell>
							<TableCell align="right">
								{currencyBRLMask(
									totalUnitValueIndirectCost,
									{ decimalPlaces: 3 },
								)}
							</TableCell>
							<TableCell />
							{showExtraColumns && (
								<>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
								</>
							)}
							<TableCell
								sx={{
									width: '0.001%',
									backgroundColor,
									padding: 0,
									textAlign: 'center',
								}}
							/>
						</TableRow>

						<TableRow>
							<TableCell>Comissão (FPV.co)</TableCell>
							<TableCell align="right">
								{currencyBRLMask(
									salePriceFormation?.unitValueRangeCommission
										|| 0,
									{ decimalPlaces: 3 },
								)}
							</TableCell>
							<TableCell />
							{showExtraColumns && (
								<>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
									<TableCell
										align="right"
										sx={{ backgroundColor: '#f0f0f0' }}
									/>
								</>
							)}
							<TableCell
								sx={{
									width: '0.001%',
									backgroundColor,
									padding: 0,
									textAlign: 'center',
								}}
							/>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};

export default SalePriceFormationSimulationTable;
