import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import Box from '@mui/material/Box';
import { PageHeader } from '../Common/PageHeader/PageHeader';
import DrawerFilter from '../Common/DrawerFilter';
import { PageHeaderButtonProps } from '../../interfaces/PageHeaderInterface';
import InventoryTable from './InventoryTable';
import StartInventoryModal from './Modals/StartInventoryModal';
import InventoryFilter from './InventoryFilter';
import {
	BranchParams, IInventory, InventoryQueryParams, ParamsStartInventory,
} from '../../containers/Inventory/InventoryAssets';
import { ICompany } from '../../containers/Company/CompanyAssets';
import { IBranch } from '../../containers/Branch/BranchAssets';
import useDataGridManagement from '../../hooks/useDataGridManagement';

interface InventaryProps {
	startInventory: (params: ParamsStartInventory) => void;
	getBranches: (params?: BranchParams) => void;
	getCompanies: () => void;
	setStartModal: (startModal: boolean) => void;
	getInventories: (values: Partial<InventoryQueryParams>) => void;
	inventories: IInventory[];
	companies: ICompany[];
	branches: IBranch[];
	startModal: boolean;
	loading: boolean;
	loadingInventory: boolean;
	inventoryPage: number;
	inventoryCount: number;
	inventoryTake: number;
}

const Inventory = ({
	startInventory,
	getCompanies,
	getBranches,
	getInventories,
	companies,
	startModal,
	inventoryPage,
	inventoryCount,
	inventoryTake,
	setStartModal,
	inventories,
	loading,
	loadingInventory,
	branches,
}: InventaryProps): JSX.Element => {
	const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
	const [filterValues, setFilterValues] = useState<Partial<InventoryQueryParams>>({});

	useEffect(() => {
		getCompanies();
	}, [getCompanies]);

	const {
		setFilter,
		sortModel,
		onChangePage,
		onChangePageSize,
		onSortModelChange,
	} = useDataGridManagement<InventoryQueryParams>({
		initialPageSize: inventoryTake,
		initialPage: inventoryPage,
		fetchData: getInventories,
	});

	const toggleFilterDrawer = useCallback(() => {
		setFilterDrawerOpen((prevState) => !prevState);
	}, []);

	const sendFilter = useCallback((values: Partial<InventoryQueryParams>) => {
		const pageFilter = {
			...values,
			skip: 0,
		};
		setFilter(pageFilter);
	}, [setFilter]);

	const handleFilter = useCallback((params: Partial<InventoryQueryParams>) => {
		setFilterValues(params);
		setFilterDrawerOpen(false);
		sendFilter(params);
	}, [sendFilter]);

	const handleModalStartInventory = useCallback(() => {
		setStartModal(true);
	}, [setStartModal]);

	const onClose = useCallback((): void => {
		setStartModal(false);
	}, [setStartModal]);

	const filterMemo = useMemo(
		() => (
			<DrawerFilter
				open={isFilterDrawerOpen}
				onClose={toggleFilterDrawer}
			>
				<InventoryFilter
					companies={companies}
					handlerValueFilter={handleFilter}
					getBranches={getBranches}
					branches={branches}
					initialValues={filterValues}
				/>
			</DrawerFilter>
		),
		[
			branches,
			companies,
			filterValues,
			getBranches,
			handleFilter,
			isFilterDrawerOpen,
			toggleFilterDrawer,
		],
	);

	const headerButtonsProps = useMemo(
		(): PageHeaderButtonProps[] => [
			{
				variant: 'contained',
				color: 'primary',
				type: 'submit',
				fullWidth: true,
				onClick: handleModalStartInventory,
				text: 'Iniciar',
			},
			{
				variant: 'contained',
				color: 'primary',
				onClick: toggleFilterDrawer,
				text: 'Filtro',
				startIcon: <FilterListIcon />,
			},
		],
		[
			handleModalStartInventory,
			toggleFilterDrawer,
		],
	);

	return (
		<>
			<Box className="content">
				<PageHeader
					title="Inventário"
					buttons={headerButtonsProps}
				/>
				{filterMemo}
				<InventoryTable
					inventories={inventories}
					loading={loadingInventory}
					inventoryPage={inventoryPage}
					onChangePage={onChangePage}
					onChangePageSize={onChangePageSize}
					onSortModelChange={onSortModelChange}
					sortModel={sortModel}
					inventoryCount={inventoryCount}
					inventoryTake={inventoryTake}
				/>
			</Box>
			<StartInventoryModal
				open={startModal}
				onClose={onClose}
				companies={companies}
				handleConfirmStartInventory={startInventory}
				getBranches={getBranches}
				branches={branches}
				loading={loading}
			/>
		</>
	);
};

export default Inventory;
