import React, {
	useCallback, useMemo, useState,
} from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Typography,
	Box,
	Tooltip,
	IconButton,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useFormikContext } from 'formik';
import Decimal from 'decimal.js';
import { currencyBRLMask } from '../../../helpers/intl';
import {
	initialSaleOrderSimulationItem,
	initialSalePriceFormationAnalysis,
	ISaleOrderSimulation,
} from '../../../containers/ProfitabilityAnalysis/ProfitabilityAnalysisAssets';
import {
	calculateSPPVIndexRangePriceCommission,
	calculateUnitValueRangePriceCommission,
	getSalePriceFormation,
	getSelectedItem,
	getSelectedItemIndex,
} from '../../../helpers/ProfitabilityAnalysisCalculations';
import { useFormikValuesUpdater } from '../../../hooks/useFormikValuesUpdater';
import { ProfitabilityAnalysisStatus } from '../../../enums/ProfitabilityAnalysisStatus';

interface IRangePriceCommissionSimulationProps {
	updateSelectedRangeSimulation(
		rangeCommissionId: string,
		salePriceFormationId: string,
		selectedRangeSimulation: boolean,
	): void;
}

const RangePriceCommissionSimulationTable = ({
	updateSelectedRangeSimulation,
}: IRangePriceCommissionSimulationProps): JSX.Element => {
	const { values, setFieldValue } = useFormikContext<ISaleOrderSimulation>();
	const [showExtraColumns, setShowExtraColumns] = useState(true);

	const selectedItem = useMemo(
		() => getSelectedItem(values.saleOrderSimulationItems, initialSaleOrderSimulationItem),
		[values.saleOrderSimulationItems],
	);

	const selectedItemIndex = useMemo(
		() => getSelectedItemIndex(values.saleOrderSimulationItems),
		[values.saleOrderSimulationItems],
	);

	const salePriceFormation = useMemo(
		() => getSalePriceFormation(selectedItem, initialSalePriceFormationAnalysis),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectedItem],
	);

	const isReadOnly = useMemo(
		() => [
			ProfitabilityAnalysisStatus.APPROVED,
			ProfitabilityAnalysisStatus.REJECTED,
		].includes(values.status),
		[values.status],
	);

	const handleRowClick = useCallback(
		(rangeCommission: any, index: number): void => {
			if (isReadOnly || !salePriceFormation?.rangeCommissionSalePriceFormations) return;

			salePriceFormation.indexValueRangeCommissionSimulation = salePriceFormation
				.rangeCommissionSalePriceFormations[index].sppvIndex;
			salePriceFormation.unitValueRangeCommissionSimulation = salePriceFormation
				.rangeCommissionSalePriceFormations[index].sppvUnitValueR;

			const updatedRangeCommissionSalePriceFormations = salePriceFormation
				.rangeCommissionSalePriceFormations.map((range, i) => ({
					...range,
					selectedRangeSimulation: i === index,
				}));

			setFieldValue(
				`saleOrderSimulationItems[${selectedItemIndex}].salePriceFormation.rangeCommissionSalePriceFormations`,
				updatedRangeCommissionSalePriceFormations,
			);

			updateSelectedRangeSimulation(
				rangeCommission.rangeCommissionId,
				salePriceFormation?.id || '',
				true,
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			salePriceFormation.rangeCommissionSalePriceFormations,
			salePriceFormation?.id, selectedItemIndex],
	);

	const calculations = useMemo(() => {
		if (
			!selectedItem
			|| !salePriceFormation
			|| !salePriceFormation.rangeCommissionSalePriceFormations
		) return { fieldValues: {}, selectedRangeCommissionRate: null };

		const newFieldValues: Record<string, any> = {};

		values.saleOrderSimulationItems.forEach((item) => {
			if (!item.salePriceFormation) return;

			const newIndexes: { [key: string]: string } = {};

			salePriceFormation.rangeCommissionSalePriceFormations?.forEach((rangeCommission) => {
				const indexValue = new Decimal(1)
					.div(
						new Decimal(1).minus(
							new Decimal(rangeCommission.commissionRate)
								.div(100)
								.plus(new Decimal(salePriceFormation.totalIndirectCost || 0).div(100)),
						),
					)
					.toFixed(4)
					.replace('.', ',');

				newIndexes[rangeCommission.commissionRate.toString()] = indexValue;

				if (Number(rangeCommission.commissionRate) === Number(salePriceFormation.baseCommission)) {
					salePriceFormation.indexValue = indexValue.replace(',', '.');
				}
			});
			salePriceFormation.indexValues = newIndexes;
			newFieldValues[`saleOrderSimulationItems[${selectedItemIndex}].salePriceFormation.indexValues`] = newIndexes;
		});

		const totalIndirectCostSimulation = salePriceFormation.totalIndirectCostSimulation || 0;

		const updatedRangeCommissionSalePriceFormations = salePriceFormation
			.rangeCommissionSalePriceFormations.map(
				(rangeCommission) => {
					const newSPPVIndexValue = calculateSPPVIndexRangePriceCommission(
						rangeCommission.commissionRate,
						totalIndirectCostSimulation,
					);

					const sppvUnitValueR = calculateUnitValueRangePriceCommission(
						salePriceFormation.totalDirectCost || 0,
						newSPPVIndexValue,
						rangeCommission.commissionRate,
						totalIndirectCostSimulation,
					);

					return {
						...rangeCommission,
						sppvIndex: newSPPVIndexValue,
						sppvUnitValueR,
					};
				},
			);

		newFieldValues[`saleOrderSimulationItems[${selectedItemIndex}].salePriceFormation.rangeCommissionSalePriceFormations`] = updatedRangeCommissionSalePriceFormations;

		const selectedRangeCommission = updatedRangeCommissionSalePriceFormations.find(
			(rangeCommission) => rangeCommission.selectedRangeSimulation,
		);

		const matchedRange = updatedRangeCommissionSalePriceFormations.find(
			(rangeCommission) => rangeCommission.commissionRate === selectedItem.commission,
		);

		let selectedRangeCommissionRate = null;

		if (matchedRange) {
			const indexValue = salePriceFormation.indexValues?.[matchedRange.commissionRate.toString()] || '1';

			const unitValueRangeCommission = calculateUnitValueRangePriceCommission(
				salePriceFormation.totalDirectCost || 0,
				parseFloat(indexValue.replace(',', '.')),
				matchedRange.commissionRate,
				salePriceFormation.totalIndirectCost || 0,
			);
			newFieldValues[`saleOrderSimulationItems[${selectedItemIndex}].salePriceFormation.unitValueRangeCommission`] = unitValueRangeCommission;
		}

		if (selectedRangeCommission && selectedRangeCommission.sppvIndex) {
			const sppvIndexValue = selectedRangeCommission.sppvIndex;
			const unitValueRangeCommissionSimulation = calculateUnitValueRangePriceCommission(
				salePriceFormation.totalDirectCost || 0,
				sppvIndexValue,
				selectedRangeCommission.commissionRate,
				salePriceFormation.totalIndirectCostSimulation || 0,
			);

			newFieldValues[`saleOrderSimulationItems[${selectedItemIndex}].salePriceFormation.indexValueRangeCommissionSimulation`] = selectedRangeCommission.sppvIndex;
			newFieldValues[`saleOrderSimulationItems[${selectedItemIndex}].salePriceFormation.unitValueRangeCommissionSimulation`] = unitValueRangeCommissionSimulation;

			selectedRangeCommissionRate = selectedRangeCommission.commissionRate;
		} else if (!selectedRangeCommission && matchedRange && matchedRange.sppvIndex) {
			const updatedRangeCommissions = updatedRangeCommissionSalePriceFormations
				.map((rangeCommission) => {
					if (rangeCommission.rangeCommissionId === matchedRange.rangeCommissionId) {
						return {
							...rangeCommission,
							selectedRangeSimulation: true,
						};
					}
					return rangeCommission;
				});

			newFieldValues[`saleOrderSimulationItems[${selectedItemIndex}].salePriceFormation.rangeCommissionSalePriceFormations`] = updatedRangeCommissions;

			const sppvIndexValue = matchedRange.sppvIndex;
			const unitValueRangeCommissionSimulation = calculateUnitValueRangePriceCommission(
				salePriceFormation.totalDirectCost || 0,
				sppvIndexValue,
				matchedRange.commissionRate,
				salePriceFormation.totalIndirectCostSimulation || 0,
			);

			newFieldValues[`saleOrderSimulationItems[${selectedItemIndex}].salePriceFormation.indexValueRangeCommissionSimulation`] = matchedRange.sppvIndex;
			newFieldValues[`saleOrderSimulationItems[${selectedItemIndex}].salePriceFormation.unitValueRangeCommissionSimulation`] = unitValueRangeCommissionSimulation;
			selectedRangeCommissionRate = matchedRange.commissionRate;
		}

		return { fieldValues: newFieldValues, selectedRangeCommissionRate };
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		salePriceFormation,
		salePriceFormation?.totalIndirectCostSimulation,
		salePriceFormation?.indexValues,
		salePriceFormation?.indexValueRangeCommissionSimulation,
		selectedItemIndex,
	]);

	useFormikValuesUpdater(
		calculations.fieldValues,
		[calculations],
	);

	const backgroundColor = useMemo(
		() => (showExtraColumns ? 'primary.dark' : 'primary.main'),
		[showExtraColumns],
	);

	if (!selectedItem || !selectedItem.salePriceFormation) {
		return (
			<Typography variant="body2">
				Selecione um item para visualizar a simulação de comissões.
			</Typography>
		);
	}

	return (
		<Box sx={{ display: 'flex', position: 'relative' }}>
			<TableContainer component={Paper} sx={{ flexGrow: 1 }}>
				<Table size="small" stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell>
								<strong>Faixa</strong>
							</TableCell>
							<TableCell align="right">
								<strong>Comissão</strong>
							</TableCell>
							<TableCell align="right">
								<strong>Índice (FPV.fc.I)</strong>
							</TableCell>
							<TableCell align="right">
								<strong>R$ Unitário (FPV.pm.V)</strong>
							</TableCell>
							{showExtraColumns && (
								<>
									<TableCell align="right" sx={{ backgroundColor: '#f0f0f0' }}>
										<strong>Índice (SPPV.fc.I)</strong>
									</TableCell>
									<TableCell align="right" sx={{ backgroundColor: '#f0f0f0' }}>
										<strong>R$ Unitário (SPPV.fc.V)</strong>
									</TableCell>
								</>
							)}
							<TableCell
								sx={{
									width: '0.001%',
									backgroundColor,
									padding: 0,
									textAlign: 'center',
								}}
							>
								<Tooltip
									title={
										showExtraColumns
											? 'Ocultar colunas de simulação'
											: 'Visualizar colunas de simulação'
									}
								>
									<IconButton
										sx={{ color: 'white' }}
										onClick={() => setShowExtraColumns(!showExtraColumns)}
									>
										{showExtraColumns ? (
											<VisibilityOffIcon />
										) : (
											<VisibilityIcon />
										)}
									</IconButton>
								</Tooltip>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{salePriceFormation.rangeCommissionSalePriceFormations?.map(
							(rangeCommission, index) => {
								const isHighlighted = selectedItem.commission === rangeCommission.commissionRate;
								const isExtraColumnSelected = rangeCommission.selectedRangeSimulation;

								const defaultCellStyle = {
									fontWeight: isHighlighted ? 'bold' : 'normal',
									backgroundColor: 'transparent',
									cursor: 'default',
								};

								const extraCellStyle = {
									fontWeight: isExtraColumnSelected ? 'bold' : 'normal',
									backgroundColor: isExtraColumnSelected ? '#e0e0e0' : 'transparent',
									cursor: 'pointer',
								};

								const indexValue = salePriceFormation.indexValues?.[
									rangeCommission.commissionRate.toString()
								] || '0';
								const unitValue = calculateUnitValueRangePriceCommission(
									salePriceFormation.totalDirectCost || 0,
									parseFloat(indexValue.replace(',', '.')),
									rangeCommission.commissionRate,
									salePriceFormation.totalIndirectCost || 0,
								);

								const sppvIndex = salePriceFormation?.rangeCommissionSalePriceFormations?.[
									index
								]?.sppvIndex || 0;
								const sppvUnitValue = salePriceFormation?.rangeCommissionSalePriceFormations?.[
									index
								]?.sppvUnitValueR || 0;

								return (
									<TableRow key={rangeCommission.rangeCommission.range}>
										<TableCell sx={defaultCellStyle}>
											{rangeCommission.rangeCommission.range}
										</TableCell>
										<TableCell align="right" sx={defaultCellStyle}>
											{Number(rangeCommission.commissionRate)
												.toFixed(1)
												.replace('.', ',')}
											%
										</TableCell>
										<TableCell align="right" sx={defaultCellStyle}>{indexValue}</TableCell>
										<TableCell align="right" sx={defaultCellStyle}>
											{currencyBRLMask(unitValue, { decimalPlaces: 3 })}
										</TableCell>
										{showExtraColumns && (
											<>
												<TableCell
													align="right"
													sx={{
														...extraCellStyle,
														backgroundColor: '#f0f0f0',
													}}
													onClick={() => !isReadOnly && handleRowClick(rangeCommission, index)}
												>
													{sppvIndex.toFixed(4)}
												</TableCell>
												<TableCell
													align="right"
													sx={{
														...extraCellStyle,
														backgroundColor: '#f0f0f0',
													}}
													onClick={() => handleRowClick(rangeCommission, index)}
												>
													{currencyBRLMask(sppvUnitValue, { decimalPlaces: 3 })}
												</TableCell>
											</>
										)}
									</TableRow>
								);
							},
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<Box
				sx={{
					width: '40px',
					backgroundColor,
					position: 'absolute',
					right: 0,
					top: '40px',
					bottom: 0,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					height: 'calc(100% - 40px)',
				}}
			>
				<Typography
					sx={{
						writingMode: 'vertical-rl',
						transform: 'rotate(180deg)',
						color: 'white',
						fontWeight: 'bold',
						fontSize: '0.75rem',
						letterSpacing: '1px',
						textAlign: 'center',
						lineHeight: 'normal',
					}}
				>
					Simulação
				</Typography>
			</Box>
		</Box>
	);
};

export default RangePriceCommissionSimulationTable;
