import React, {
	Dispatch, Reducer, useMemo, useReducer,
} from 'react';
import {
	OptionsObject,
	SnackbarKey,
	SnackbarMessage,
	useSnackbar,
} from 'notistack';
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { ptBR } from 'date-fns/locale';
import { AxiosError } from 'axios';
import omit from 'lodash/omit';
import InventoryPendingTaskPresentational from '../../components/Task/InventoryPendingTask';
import {
	InventoryTaskQueryParams,
	InventoryTaskTransferParams,
	InventoryTaskTransferProductData,
	ReleaseTasksParams,
	RemoveTasksParams,
	ReprocessTasksParams,
} from '../../interfaces/InventoryTaskQueryParams';
import {
	getTasks,
	releaseTasks,
	reprocessLocationTasks,
	finishTask,
	transferUserTask,
	createTransferProductTask,
} from '../../services/inventoryTask';
import { InventoryTask } from './InventoryTaskAssets';
import { setDate } from '../../helpers/DateUtils';
import { ProductQueryParams } from '../../interfaces/ProductQueryParams';
import { getProductLocationData, getProducts } from '../../services/product';
import { ILocationProductData, IProduct } from '../Product/ProductAssets';
import { ErrorResponse } from '../../interfaces/ErrorResponse';
import { InventoryTaskAction } from '../../enums/InventoryTaskAction';
import { filterObject } from '../../helpers/Utils';

enum ActionType {
  LOADING,
  TASKS,
  UPDATE_USER,
  FILTERS,
  PRODUCTS,
  PRODUCT_LOCATIONS,
}

interface IState {
  loading: boolean;
  tasks: InventoryTask[];
  tasksPages: number;
  tasksPage: number;
  tasksTake: number;
  products: IProduct[];
  productLocations: ILocationProductData[];
}

type TAction =
  | { type: ActionType.LOADING; payload: { loading: boolean } }
  | {
      type: ActionType.TASKS;
      payload: {
        tasks: InventoryTask[];
        tasksPages: number;
        tasksPage: number;
        tasksTake: number;
      };
    }
  | { type: ActionType.PRODUCTS; payload: { products: IProduct[] } }
  | {
      type: ActionType.PRODUCT_LOCATIONS;
      payload: { productLocations: ILocationProductData[] };
    };

interface ITaskActions {
  setLoading(loading: boolean): void;
  getTasks(queryParams: InventoryTaskQueryParams): void;
  releaseTasks: (data: ReleaseTasksParams) => void;
  reprocessLocationTasks: (data: ReprocessTasksParams) => void;
  finishTask: (data: RemoveTasksParams) => void;
  transferUserTask: (userId: string, data: InventoryTaskTransferParams) => void;
  getProducts: (queryParams?: ProductQueryParams) => Promise<void>;
  getProductLocationsData: (productId: string) => Promise<void>;
  createTransferProductTask: (data: InventoryTaskTransferProductData) => void;
}

const initialState: IState = {
	loading: false,
	tasks: [],
	tasksPages: 0,
	tasksPage: 0,
	tasksTake: 10,
	products: [],
	productLocations: [],
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.TASKS:
			return {
				...state,
				tasks: action.payload.tasks,
				tasksPages: action.payload.tasksPages,
				tasksPage: action.payload.tasksPage,
				tasksTake: action.payload.tasksTake,
			};
		case ActionType.PRODUCTS:
			return { ...state, products: action.payload.products };
		case ActionType.PRODUCT_LOCATIONS:
			return { ...state, productLocations: action.payload.productLocations };
		default:
			throw new Error();
	}
};

const TaskActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined
  ) => SnackbarKey,
): ITaskActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getTasks(queryParams: InventoryTaskQueryParams) {
			actions.setLoading(true);

			const queryParamsData = omit(queryParams, ['indexContext']);

			const type = queryParams.type !== undefined ? queryParams.type : InventoryTaskAction.SUPPLY;
			const take = queryParams.take ?? 10;
			const skip = queryParams.skip ?? 0;
			const params: InventoryTaskQueryParams = {
				...queryParamsData,
				type,
				skip: skip * take,
			};

			if (params.startDate) {
				params.startDate = setDate(params.startDate as Date, {
					hours: 0,
					minutes: 0,
					seconds: 0,
				});

				params.startDate = format(params.startDate, 'yyyy-MM-dd', {
					locale: ptBR,
				});
			}

			if (params.endDate) {
				params.endDate = setDate(params.endDate as Date, {
					hours: 23,
					minutes: 59,
					seconds: 59,
				});
				params.endDate = format(params.endDate, 'yyyy-MM-dd', { locale: ptBR });
			}

			getTasks(params)
				.then((response) => {
					dispatch({
						type: ActionType.TASKS,
						payload: {
							tasks: response.data.data,
							tasksPages: response.data.count,
							tasksPage: queryParams.skip,
							tasksTake: take,
						},
					});
				})
				.catch((error) => {
					enqueueSnackbar(
						error.response?.data.message || 'Erro ao obter informações',
						{
							variant: 'error',
						},
					);
				});
			actions.setLoading(false);
		},
		releaseTasks(data: ReleaseTasksParams) {
			actions.setLoading(true);
			releaseTasks(data)
				.then((response) => {
					enqueueSnackbar(
						response.data.message || 'Tarefas liberadas com sucesso!',
						{ variant: 'success' },
					);
				})
				.catch((error) => {
					enqueueSnackbar(
						error.response?.data.message || 'Erro ao reprocessar localizações',
						{
							variant: 'error',
						},
					);
				});
			actions.setLoading(false);
		},
		reprocessLocationTasks(data: ReprocessTasksParams) {
			actions.setLoading(true);

			let params: InventoryTaskQueryParams;
			const paramsString = localStorage.getItem('inventoryTaskFilterParams');

			if (paramsString) {
				const parsedParams = JSON.parse(paramsString);
				params = {
					...parsedParams,
					skip: 0,
				};
			}

			reprocessLocationTasks(data)
				.then((response) => {
					enqueueSnackbar(
						response.data.message || 'Tarefas reprocessadas com sucesso!',
						{ variant: 'success' },
					);
					actions.getTasks(filterObject(params) as InventoryTaskQueryParams);
				})
				.catch((error) => {
					enqueueSnackbar(
						error.response?.data.message || 'Erro ao reprocessar localizações',
						{
							variant: 'error',
						},
					);
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		finishTask(data: RemoveTasksParams) {
			actions.setLoading(true);
			finishTask(data)
				.then((response) => {
					enqueueSnackbar(
						response.data.message || 'Tarefa finalizada com sucesso!',
						{ variant: 'success' },
					);
				})
				.catch((error) => {
					enqueueSnackbar(
						error.response?.data.message || 'Erro ao finalizar tarefa',
						{
							variant: 'error',
						},
					);
				});
			actions.setLoading(false);
		},
		transferUserTask(userId: string, data: InventoryTaskTransferParams) {
			actions.setLoading(true);
			transferUserTask(userId, data)
				.then((response) => {
					enqueueSnackbar(
						response.data.message || 'Tarefa transferida com sucesso!',
						{ variant: 'success' },
					);
				})
				.catch((error) => {
					enqueueSnackbar(
						error.response?.data.message || 'Erro ao transferir tarefa',
						{
							variant: 'error',
						},
					);
				});
			actions.setLoading(false);
		},
		async getProducts(queryParams?: ProductQueryParams) {
			actions.setLoading(true);
			try {
				const response = await getProducts(queryParams);
				dispatch({
					type: ActionType.PRODUCTS,
					payload: {
						products: response.data.data,
					},
				});
			} catch (error) {
				const axiosError = error as AxiosError;
				enqueueSnackbar(
					axiosError.response?.data.message
            || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
					{ variant: 'error' },
				);
			} finally {
				actions.setLoading(false);
			}
		},
		async getProductLocationsData(productId: string) {
			actions.setLoading(true);
			try {
				const response = await getProductLocationData(productId);

				dispatch({
					type: ActionType.PRODUCT_LOCATIONS,
					payload: {
						productLocations: response.data,
					},
				});
			} catch (error) {
				const axiosError = error as AxiosError;
				enqueueSnackbar(
					axiosError.response?.data.message
            || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
					{ variant: 'error' },
				);
			} finally {
				actions.setLoading(false);
			}
		},
		createTransferProductTask(data: InventoryTaskTransferProductData) {
			actions.setLoading(true);
			createTransferProductTask(data)
				.then((response) => {
					enqueueSnackbar(
						response.data.message || 'Transferência iniciada com sucesso!',
						{ variant: 'success' },
					);
				})
				.catch((error: AxiosError<ErrorResponse>) => {
					enqueueSnackbar(
						error.response?.data.message
              || 'Erro ao iniciar processo de transferência',
						{
							variant: 'error',
						},
					);
				});
			actions.setLoading(false);
		},
	};

	return actions;
};

const InventoryPendingTaskContainer = (): JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(
		reducer,
		initialState,
	);
	const { enqueueSnackbar } = useSnackbar();
	const actions = useMemo(
		() => TaskActions(dispatch, enqueueSnackbar),
		[dispatch, enqueueSnackbar],
	);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <InventoryPendingTaskPresentational {...state} {...actions} />;
};

export default InventoryPendingTaskContainer;
