import { Box, Paper, Typography } from '@mui/material';
import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { FilterList } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { PageHeader } from '../Common/PageHeader/PageHeader';
import DrawerFilter from '../Common/DrawerFilter';
import { PageHeaderButtonProps } from '../../interfaces/PageHeaderInterface';
import InventoryTasksTable from './InventoryTasksTable';
import InventoryTasksFilter from './InventoryTasksFilter';
import CreateInventoryTaskModal, { CreateInventoryTaskFormProps } from './Modals/CreateInventoryTaskModal';
import { ILocationWithPagination } from '../../containers/Location/LocationAssets';
import {
	IInventoryInfo,
	IInventoryItems,
	InventoryTaskPostParams,
} from '../../containers/Inventory/InventoryAssets';
import { LocationProductQueryParams } from '../../interfaces/LocationProduct';
import {
	InventoryItemsByIdParams,
	ParamsFinishInventoryTask,
	ParamsStartInventoryTask,
	RecountParams,
} from '../../interfaces/InventoryParams';
import useDataGridManagement from '../../hooks/useDataGridManagement';
import { ProductQueryParams } from '../../interfaces/ProductQueryParams';
import { IProduct } from '../../containers/Product/ProductAssets';
import useConfirmationDialog from '../../hooks/useConfirmationDialog';
import { AlertTypes } from '../../enums/ConfirmationDialogType';
import { User } from '../../containers/User/UserAssets';
import { UserQueryParams } from '../../interfaces/UserQueryParams';
import CreateInventoryTaskSecondOrPlus from './Modals/CreateInventoryTaskSecondOrPlus';
import { InventoryStatus } from '../../enums/inventory';

interface InventoryTaskProps {
	loading: boolean;
	openModalCreateTask: boolean;
	setCreateModalOpen(open: boolean): void;
	getInventoryItemsById: (id: string, queryParams: InventoryItemsByIdParams) => void;
	locations: ILocationWithPagination;
	products: IProduct[];
	createInventoryTask: (data: InventoryTaskPostParams) => void;
	getLocations: (queryParams?: LocationProductQueryParams) => void;
	getProducts: (queryParams?: ProductQueryParams) => void;
	finishInventoryTask: (data: ParamsFinishInventoryTask) => void;
	startInventoryTask: (data?: ParamsStartInventoryTask) => void;
	inventoryItems: IInventoryItems[];
	inventoryItemsCount: number;
	inventoryItemsTake: number;
	inventoryItemsPage: number;
	users: User[];
	getUsers: (queryParams: UserQueryParams) => void;
	postRecount(params: RecountParams): void;
	finishInventory(inventoryId: string): void;
	inventory: IInventoryInfo;
}

const InventoryTasks = ({
	loading,
	finishInventoryTask,
	products,
	startInventoryTask,
	getUsers,
	users,
	getProducts,
	setCreateModalOpen,
	openModalCreateTask,
	inventoryItems,
	getLocations,
	inventoryItemsTake,
	inventoryItemsPage,
	inventoryItemsCount,
	createInventoryTask,
	locations,
	getInventoryItemsById,
	postRecount,
	finishInventory,
	inventory,
}: InventoryTaskProps): JSX.Element => {
	const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
	const [isCreateOperatorTaskOpen, setIsCreateOperatorTaskOpen] = useState(false);
	const [filterValues, setFilterValues] = useState<Partial<InventoryItemsByIdParams>>({
		productIds: [],
		operatorIds: [],
	});
	const [selectedTask, setSelectedTask] = useState('');
	const [selectedProducts, setSelectedProducts] = useState<IProduct[]>([]);
	const [selectedOperators, setSelectedOperators] = useState<User[]>([]);

	const { id } = useParams<{ id: string }>();

	useEffect(() => {
		getProducts({ skip: 0 });
		getUsers({ skip: 0 });
	}, [getProducts, getUsers]);

	const {
		setFilter,
		sortModel,
		onChangePage,
		onChangePageSize,
		onSortModelChange,
	} = useDataGridManagement<InventoryItemsByIdParams>({
		initialPageSize: inventoryItemsTake,
		initialPage: inventoryItemsPage,
		fetchData: (params) => {
			if (id) {
				return getInventoryItemsById(id, params);
			}
			return Promise.resolve(null);
		},
	});

	useEffect(() => {
		getLocations({ skip: 0 });
	}, [getLocations]);

	const { requestConfirm, confirmationDialog } = useConfirmationDialog();

	const toggleFilterDrawer = useCallback(() => {
		setFilterDrawerOpen((prevState) => !prevState);
	}, []);

	const handleModalCreateInventoryTask = useCallback(() => {
		setCreateModalOpen(true);
	}, [setCreateModalOpen]);

	const handleRecount = useCallback(() => {
		if (selectedTask && id) {
			requestConfirm({
				title: 'Deseja recontar o produto selecionado?',
				alertType: AlertTypes.ERROR,
				description: 'Tem certeza que deseja recontar?',
				callback: () => postRecount({ inventoryItemCountId: selectedTask }),
			});
		}
	}, [id, postRecount, requestConfirm, selectedTask]);

	const handleFinishInventoryTask = useCallback(() => {
		if (id) {
			requestConfirm({
				title: `Encerrar ${inventory.countNumber}ª contagem`,
				alertType: AlertTypes.ERROR,
				description: `Tem certeza que deseja encerrar ${inventory.countNumber}ª contagem?`,
				callback: () => finishInventoryTask({
					inventoryId: id,
					countNumber: inventory.countNumber,
				}),
			});
		}
	}, [
		inventory.countNumber,
		finishInventoryTask,
		id,
		requestConfirm,
	]);

	const handleStartInventoryTask = useCallback(() => {
		if (id && inventory.countNumber === 1) {
			requestConfirm({
				title: 'Iniciar contagem',
				alertType: AlertTypes.ERROR,
				description: 'Tem certeza que deseja iniciar 1ª contagem?',
				callback: () => startInventoryTask({
					inventoryId: id,
				}),
			});
		}
		if (id && inventory.countNumber > 1) {
			setIsCreateOperatorTaskOpen(true);
		}
	}, [
		inventory.countNumber,
		id,
		requestConfirm,
		startInventoryTask,
	]);

	const handleConfirmCreateInventoryTask = useCallback((params: CreateInventoryTaskFormProps) => {
		if (id) {
			createInventoryTask({ ...params, inventoryId: id });
		}
	}, [createInventoryTask, id]);

	const handleConfirmOperatorInventoryTask = useCallback((
		operatorIds: string[],
	) => {
		if (id) {
			startInventoryTask({ inventoryId: id, operatorIds, countNumber: inventory.countNumber });
			setIsCreateOperatorTaskOpen(false);
		}
	}, [inventory.countNumber, id, startInventoryTask]);

	const sendFilter = useCallback((
		values: Partial<InventoryItemsByIdParams>,
	) => {
		const pageFilter = {
			...values,
			skip: 0,
		};
		setFilterValues(pageFilter);
		setFilter(pageFilter);
		setFilterDrawerOpen(false);
	}, [setFilter]);

	const filterMemo = useMemo(
		() => (
			<DrawerFilter open={isFilterDrawerOpen} onClose={() => setFilterDrawerOpen(false)}>
				<InventoryTasksFilter
					loading={loading}
					products={products}
					sendFilter={sendFilter}
					users={users}
					initialValues={filterValues}
					countNumber={inventory.countNumber}
					getUsers={getUsers}
					getProducts={getProducts}
					setSelectedProducts={setSelectedProducts}
					selectedProducts={selectedProducts}
					setSelectedOperators={setSelectedOperators}
					selectedOperators={selectedOperators}
				/>
			</DrawerFilter>
		),
		[
			selectedOperators,
			selectedProducts,
			getProducts,
			getUsers,
			inventory.countNumber,
			filterValues,
			isFilterDrawerOpen,
			loading,
			products,
			sendFilter,
			users,
		],
	);

	const headerButtonsProps = useMemo(
		(): PageHeaderButtonProps[] => [
			{
				variant: 'contained',
				color: 'primary',
				type: 'submit',
				fullWidth: true,
				onClick: handleRecount,
				text: 'Recontar',
				show: inventory.startCount && Boolean(selectedTask),
			},
			{
				variant: 'contained',
				color: 'primary',
				type: 'submit',
				fullWidth: true,
				onClick: handleModalCreateInventoryTask,
				text: 'Criar',
				show: inventory.status !== InventoryStatus.FINISHED,
			},
			{
				variant: 'contained',
				color: 'primary',
				type: 'submit',
				fullWidth: true,
				onClick: handleStartInventoryTask,
				text: `Iniciar ${inventory.countNumber}ª`,
				show: (!inventory.startCount
					&&					inventory.haveItemsForNextCount)
					|| (!inventory.startCount && inventory.countNumber === 1),
			},
			{
				variant: 'contained',
				color: 'primary',
				type: 'submit',
				fullWidth: true,
				onClick: handleFinishInventoryTask,
				text: `Encerrar ${inventory.countNumber}ª`,
				show: inventory.startCount,
			},
			{
				variant: 'contained',
				color: 'primary',
				type: 'submit',
				fullWidth: true,
				onClick: () => {
					if (id) {
						finishInventory(id);
					}
				},
				text: 'Finalizar',
				show: inventory.status !== InventoryStatus.FINISHED
				&& (inventory.countNumber > 4 || (!inventory.haveItemsForNextCount
					&& inventoryItems.length > 0)),
			},
			{
				variant: 'contained',
				color: 'primary',
				onClick: toggleFilterDrawer,
				text: 'Filtro',
				startIcon: <FilterList />,
			},
		],
		[
			inventoryItems,
			selectedTask,
			inventory.status,
			inventory.haveItemsForNextCount,
			inventory.countNumber,
			finishInventory,
			handleFinishInventoryTask,
			handleModalCreateInventoryTask,
			handleRecount,
			handleStartInventoryTask,
			id,
			inventory.startCount,
			toggleFilterDrawer,
		],
	);

	return (
		<>
			<Box className="content">
				<Box>
					<PageHeader
						title="Tarefas do Inventário"
						buttons={headerButtonsProps}
					/>
					<Typography variant="caption">{`N° DOCUMENTO: ${inventory.document} - CONTAGEM ATUAL: ${inventory.countNumber}`}</Typography>
				</Box>
				{filterMemo}
				<Box component={Paper} p={2}>
					<InventoryTasksTable
						loading={loading}
						inventoryItems={inventoryItems}
						inventoryItemsCount={inventoryItemsCount}
						inventoryItemsTake={inventoryItemsTake}
						inventoryItemsPage={inventoryItemsPage}
						sortModel={sortModel}
						onChangePageSize={onChangePageSize}
						onChangePage={onChangePage}
						onSortModelChange={onSortModelChange}
						handleSelectedTask={setSelectedTask}
					/>
				</Box>
			</Box>
			<CreateInventoryTaskModal
				open={openModalCreateTask}
				onClose={() => setCreateModalOpen(false)}
				handleConfirmCreateInventoryTask={handleConfirmCreateInventoryTask}
				locations={locations}
				loading={loading}
				startCount={inventory.startCount}
				operators={users}
				getUsers={getUsers}
				countNumber={inventory.countNumber}
			/>
			<CreateInventoryTaskSecondOrPlus
				loading={loading}
				operators={users}
				handleConfirmOperatorsInventoryTask={handleConfirmOperatorInventoryTask}
				onClose={() => setIsCreateOperatorTaskOpen(false)}
				open={isCreateOperatorTaskOpen}
				getUsers={getUsers}
			/>
			{confirmationDialog}
		</>
	);
};

export default InventoryTasks;
