import React, { useCallback, useMemo } from 'react';
import {
	Card, CardContent, Typography, Box,
	Grid,
	TableContainer,
	Table,
	TableBody,
	Paper,
	TableCell,
	TableHead,
	TableRow,
} from '@mui/material';
import { DataGrid, GridRowsProp, GridSelectionModel } from '@mui/x-data-grid';
import { useFormik, FormikContext, Form } from 'formik';
import Decimal from 'decimal.js';
import { initialSaleOrderSimulation, ISaleOrderSimulation, ISaleOrderSimulationItem } from '../../containers/ProfitabilityAnalysis/ProfitabilityAnalysisAssets';
import { profitabilityAnalysisFormColumns } from '../../constants/profitabilityAnalysisFormColumns';
import SimulationDrawer from './Drawers/SimulationDrawer';
import { ItemType } from '../../enums/ItemType';
import { IIndirectCostSalePriceFormation } from '../../containers/SalePriceFormation/SalePriceFormationAssets';
import { formatDecimalValue } from '../../helpers/Utils';

interface ProfitabilityAnalysisFormProps {
	loading: boolean;
	saleOrderSimulation?: ISaleOrderSimulation;
	upsertIndirectCostToFormation(data: IIndirectCostSalePriceFormation): void;
	updateSelectedRangeSimulation(
		rangeCommissionId: string,
		salePriceFormationId: string,
		selectedRangeSimulation: boolean,
	): void;
	onSubmit: (itemId: string, data: ISaleOrderSimulationItem) => void;
}

const ProfitabilityAnalysisForm = ({
	loading,
	saleOrderSimulation,
	upsertIndirectCostToFormation,
	updateSelectedRangeSimulation,
	onSubmit,
}: ProfitabilityAnalysisFormProps): JSX.Element => {
	const formik = useFormik<ISaleOrderSimulation>({
		initialValues: {
			...initialSaleOrderSimulation,
			...saleOrderSimulation,
			saleOrderSimulationItems: saleOrderSimulation?.saleOrderSimulationItems?.map((item) => ({
				...item,
				salePriceFormation: item.salePriceFormation
					? {
						...item.salePriceFormation,
						discountIndex: item.salePriceFormation.priceListStateSalePriceFormation?.discount
							? new Decimal(item.salePriceFormation.priceListStateSalePriceFormation.discount)
								.dividedBy(
									new Decimal(100).minus(
										item.salePriceFormation.priceListStateSalePriceFormation.discount,
									),
								)
								.times(100)
								.toDecimalPlaces(3)
								.toNumber()
							: 0,
						formulas: item.salePriceFormation.formulas?.length
							? item.salePriceFormation.formulas
							: [
								{ id: '0', description: '((FPV.pv - FPV.cd) / FPV.pv) * 100' }, // Margem Bruta
								{ id: '1', description: 'FPV.pv - FPV.cd' }, // Lucro Bruto
								{ id: '2', description: '(FPV.lb - (FPV.ci.V.1 + FPV.ci.V.2 + FPV.ci.V.3 + FPV.ci.V.4 + FPV.fc.V))' }, // Lucro Líquido
								{ id: '3', description: 'FPV.ll / FPV.pv * 100' }, // Margem Lucro Líquido
							],
					}
					: undefined,
			})) || [],
		},
		onSubmit: (values) => {
			const selectedItem = values.saleOrderSimulationItems.find((item) => item.selected);
			if (selectedItem) {
				onSubmit(selectedItem.id, selectedItem);
			}
		},
	});

	const handleRowSelection = useCallback((selectionModel: GridSelectionModel) => {
		const updatedItems = formik.values.saleOrderSimulationItems.map((item) => ({
			...item,
			selected: item.id === selectionModel[0],
		}));
		formik.setFieldValue('saleOrderSimulationItems', updatedItems);
	}, [formik]);

	const salesItems = useMemo(() => formik.values.saleOrderSimulationItems
		.filter((item) => item.type === ItemType.SALE), [formik.values.saleOrderSimulationItems]);

	const bonusItems = useMemo(() => formik.values.saleOrderSimulationItems
		.filter((item) => item.type === ItemType.BONUS), [formik.values.saleOrderSimulationItems]);

	const totalSalesRow = useMemo(() => ({
		id: 'totalSales',
		nrItem: null,
		codeDescription: 'Total Vendas',
		quantity: null,
		price: null,
		total: salesItems.reduce((sum, item) => sum + item.total, 0),
		discount: null,
		discountValue: salesItems.reduce((sum, item) => sum + item.discountValue, 0),
		totalWithDiscount: salesItems.reduce((sum, item) => sum + item.totalWithDiscount, 0),
		commission: null,
		commissionValue: salesItems.reduce((sum, item) => sum + item.commissionValue, 0),
		totalDirectCost: salesItems.reduce((sum, item) => sum + (
			item.quantity * (item.salePriceFormation?.totalDirectCost || 0)), 0),
		grossProfitSaleOrder: salesItems.reduce((sum, item) => sum
		+ (item.quantity * (item.grossProfitSaleOrder || 0)), 0),
		netProfitSaleOrder: salesItems.reduce((sum, item) => sum
		+ (item.quantity * (item.netProfitSaleOrder || 0)), 0),
		grossProfitSimulation: salesItems.reduce((sum, item) => sum
		+ (item.quantity * (item.grossProfitSimulation || 0)), 0),
		netProfitSimulation: salesItems.reduce((sum, item) => sum
		+ (item.quantity * (item.netProfitSimulation || 0)), 0),
	}), [salesItems]);

	const totalBonusRow = useMemo(() => ({
		id: 'totalBonus',
		nrItem: null,
		codeDescription: 'Total Bonificações',
		quantity: null,
		price: null,
		total: bonusItems.reduce((sum, item) => sum + item.total, 0),
		discount: bonusItems.reduce((sum, item) => sum + item.discount, 0),
		discountValue: bonusItems.reduce((sum, item) => sum + item.discountValue, 0),
		totalWithDiscount: bonusItems.reduce((sum, item) => sum + item.totalWithDiscount, 0),
		commission: null,
		commissionValue: bonusItems.reduce((sum, item) => sum + item.commissionValue, 0),
		totalDirectCost: bonusItems.reduce((sum, item) => sum + (
			item.quantity * (item.salePriceFormation?.totalDirectCost || 0)), 0),
		grossProfitSaleOrder: bonusItems.reduce((sum, item) => sum
			+ (item.quantity * (item.grossProfitSaleOrder || 0)), 0),
		netProfitSaleOrder: bonusItems.reduce((sum, item) => sum
			+ (item.quantity * (item.netProfitSaleOrder || 0)), 0),
		grossProfitSimulation: bonusItems.reduce((sum, item) => sum
			+ (item.quantity * (item.grossProfitSimulation || 0)), 0),
		netProfitSimulation: bonusItems.reduce((sum, item) => sum
			+ (item.quantity * (item.netProfitSimulation || 0)), 0),
	}), [bonusItems]);

	const updatedBonusItems = useMemo(() => bonusItems.map((item) => ({
		...item,
		totalDirectCost: item.quantity * (item.salePriceFormation?.totalDirectCost || 0),
		totalWithDiscount: item.total - item.discountValue,
		grossProfitSaleOrder: item.quantity * (item.grossProfitSaleOrder || 0),
		netProfitSaleOrder: item.quantity * (item.netProfitSaleOrder || 0),
		grossProfitSimulation: item.quantity * (item.grossProfitSimulation || 0),
		netProfitSimulation: item.quantity * (item.netProfitSimulation || 0),
	})), [bonusItems]);

	const updatedSalesItems = useMemo(() => salesItems.map((item) => ({
		...item,
		totalDirectCost: item.quantity * (item.salePriceFormation?.totalDirectCost || 0),
		totalWithDiscount: item.total - item.discountValue,
		grossProfitSaleOrder: item.quantity * (item.grossProfitSaleOrder || 0),
		netProfitSaleOrder: item.quantity * (item.netProfitSaleOrder || 0),
		grossProfitSimulation: item.quantity * (item.grossProfitSimulation || 0),
		netProfitSimulation: item.quantity * (item.netProfitSimulation || 0),
	})), [salesItems]);

	const rows: GridRowsProp = useMemo(() => [
		...updatedSalesItems,
		totalSalesRow,
		...updatedBonusItems,
		totalBonusRow,
	], [updatedSalesItems, totalSalesRow, updatedBonusItems, totalBonusRow]);

	const simulationDrawerMemo = useMemo(() => {
		const hasSelectedItem = formik.values.saleOrderSimulationItems.some((item) => item.selected);
		if (hasSelectedItem) {
			return (
				<SimulationDrawer
					loading={loading}
					upsertIndirectCostToFormation={upsertIndirectCostToFormation}
					updateSelectedRangeSimulation={updateSelectedRangeSimulation}
				/>
			);
		}
		return null;
	}, [formik.values.saleOrderSimulationItems,
		loading, upsertIndirectCostToFormation, updateSelectedRangeSimulation]);

	return (
		<FormikContext.Provider value={formik}>
			<Form onSubmit={formik.handleSubmit}>
				<Box sx={{ position: 'relative', height: '100%' }}>
					<Card>
						<CardContent>
							<Grid container spacing={2}>
								<Grid item xs={5}>
									<Typography variant="h6" gutterBottom>
										Dados do Pedido de Venda
									</Typography>
									<TableContainer component={Paper}>
										<Table
											size="small"
											stickyHeader
											sx={{
												'& .MuiTableCell-root': {
													fontSize: '14px',
													padding: '4px',
												},
											}}
										>
											<TableBody>
												<TableRow sx={{ backgroundColor: '#f9f9f9', height: '48px' }}>
													<TableCell sx={{ fontWeight: 'bold', width: '25%' }}>
														Pedido Venda / Rev:
													</TableCell>
													<TableCell align="left" sx={{ width: '50%' }}>
														{formik.values.nrOrder}
														{' '}
														/
														{formik.values.revision}
													</TableCell>
													<TableCell sx={{ fontWeight: 'bold', width: '25%' }}>
														Tabela:
													</TableCell>
													<TableCell align="left" sx={{ width: '50%' }}>
														{formik.values.priceList}
													</TableCell>
												</TableRow>
												<TableRow sx={{ backgroundColor: '#ffffff', height: '48px' }}>
													<TableCell sx={{ fontWeight: 'bold', width: '25%' }}>
														Emissão:
													</TableCell>
													<TableCell align="left" sx={{ width: '50%' }}>
														{new Date(formik.values.emissionDate).toLocaleDateString()}
													</TableCell>
													<TableCell sx={{ fontWeight: 'bold', width: '25%' }}>
														Condição Pagto:
													</TableCell>
													<TableCell align="left" sx={{ width: '50%' }}>
														{formik.values.paymentTerm}
													</TableCell>
												</TableRow>
												<TableRow sx={{ backgroundColor: '#f9f9f9', height: '48px' }}>
													<TableCell sx={{ fontWeight: 'bold', width: '25%' }}>
														Vendedor:
													</TableCell>
													<TableCell align="left" sx={{ width: '50%' }}>
														{formik.values.salesman}
													</TableCell>
													<TableCell sx={{ fontWeight: 'bold', width: '25%' }}>
														Prazo Médio:
													</TableCell>
													<TableCell align="left" sx={{ width: '50%' }}>
														{formik.values.averagePaymentTerm}
													</TableCell>
												</TableRow>
												<TableRow sx={{ backgroundColor: '#ffffff', height: '48px' }}>
													<TableCell sx={{ fontWeight: 'bold', width: '25%' }}>
														Cliente:
													</TableCell>
													<TableCell align="left" sx={{ width: '50%' }}>
														{formik.values.client}
													</TableCell>
													<TableCell sx={{ fontWeight: 'bold', width: '25%' }}>
														Pedido Força Venda:
													</TableCell>
													<TableCell align="left" sx={{ width: '50%' }}>
														{formik.values.saleForceOrder}
													</TableCell>
												</TableRow>
												<TableRow sx={{ backgroundColor: '#f9f9f9', height: '48px' }}>
													<TableCell sx={{ fontWeight: 'bold', width: '25%' }}>
														Localidade:
													</TableCell>
													<TableCell align="left" sx={{ width: '50%' }}>
														{formik.values.locality}
													</TableCell>
													<TableCell sx={{ fontWeight: 'bold', width: '25%' }}>
														Frete:
													</TableCell>
													<TableCell align="left" sx={{ width: '50%' }}>
														{formik.values.deliveryType}
													</TableCell>
												</TableRow>
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
								<Grid item xs={7}>
									<Grid container spacing={2}>
										<Grid item xs={10}>
											<Typography variant="h6" gutterBottom>
												Resultado do Pedido
											</Typography>
											<TableContainer component={Paper}>
												<Table
													size="small"
													stickyHeader
													sx={{
														'& .MuiTypography-root': {
															fontSize: '14px',
														},
													}}
												>
													<TableHead>
														<TableRow>
															<TableCell><strong>Operação</strong></TableCell>
															<TableCell><strong>Venda</strong></TableCell>
															<TableCell><strong>Bonificação</strong></TableCell>
															<TableCell><strong>Rentabilidade</strong></TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														<TableRow>
															<TableCell>Receita</TableCell>
															<TableCell align="right">
																<Box display="flex" justifyContent="space-between" alignItems="center">
																	<Typography>R$</Typography>
																	<Typography>
																		{formatDecimalValue(totalSalesRow
																			.totalWithDiscount)}
																	</Typography>
																</Box>
															</TableCell>
															<TableCell align="right">
																<Box display="flex" justifyContent="space-between" alignItems="center">
																	<Typography>R$</Typography>
																	<Typography>
																		{formatDecimalValue(totalBonusRow
																			.totalWithDiscount)}
																	</Typography>
																</Box>
															</TableCell>
															<TableCell align="right">
																<Box display="flex" justifyContent="space-between" alignItems="center">
																	<Typography>R$</Typography>
																	<Typography>
																		{formatDecimalValue(totalSalesRow.totalWithDiscount)}
																	</Typography>
																</Box>
															</TableCell>
														</TableRow>
														<TableRow>
															<TableCell>Descontos (-)</TableCell>
															<TableCell align="right">
																<Box display="flex" justifyContent="space-between" alignItems="center">
																	<Typography>R$</Typography>
																	<Typography>
																		{formatDecimalValue(totalSalesRow
																			.discountValue)}
																	</Typography>
																</Box>
															</TableCell>
															<TableCell align="right">
																<Box display="flex" justifyContent="space-between" alignItems="center">
																	<Typography>R$</Typography>
																	<Typography>
																		{formatDecimalValue(totalBonusRow
																			.discountValue)}
																	</Typography>
																</Box>
															</TableCell>
															<TableCell align="right">
																<Box display="flex" justifyContent="space-between" alignItems="center">
																	<Typography>R$</Typography>
																	<Typography>
																		{formatDecimalValue(totalSalesRow.discountValue
																		+ totalBonusRow.discountValue)}
																	</Typography>
																</Box>
															</TableCell>
														</TableRow>
														<TableRow>
															<TableCell>Custos Diretos (CPV)</TableCell>
															<TableCell align="right">
																<Box display="flex" justifyContent="space-between" alignItems="center">
																	<Typography>R$</Typography>
																	<Typography>
																		{formatDecimalValue(totalSalesRow
																			.totalDirectCost)}
																	</Typography>
																</Box>
															</TableCell>
															<TableCell align="right">
																<Box display="flex" justifyContent="space-between" alignItems="center">
																	<Typography>R$</Typography>
																	<Typography>
																		{formatDecimalValue(totalBonusRow
																			.totalDirectCost)}
																	</Typography>
																</Box>
															</TableCell>
															<TableCell align="right">
																<Box display="flex" justifyContent="space-between" alignItems="center">
																	<Typography>R$</Typography>
																	<Typography>
																		{formatDecimalValue(totalSalesRow.totalDirectCost
																		+ totalBonusRow.totalDirectCost)}
																	</Typography>
																</Box>
															</TableCell>
														</TableRow>
														<TableRow>
															<TableCell>Lucro/Prejuízo Bruto</TableCell>
															<TableCell align="right">
																<Box display="flex" justifyContent="space-between" alignItems="center">
																	<Typography>R$</Typography>
																	<Typography>
																		{formatDecimalValue(totalSalesRow
																			.grossProfitSaleOrder)}
																	</Typography>
																</Box>
															</TableCell>
															<TableCell align="right">-</TableCell>
															<TableCell align="right">
																<Box display="flex" justifyContent="space-between" alignItems="center">
																	<Typography>R$</Typography>
																	<Typography>
																		{formatDecimalValue((totalSalesRow
																			.totalWithDiscount)
																			- (totalSalesRow.discountValue)
																			- (totalSalesRow.totalDirectCost
																			+ totalBonusRow.totalDirectCost))}
																	</Typography>
																</Box>
															</TableCell>
														</TableRow>
														<TableRow>
															<TableCell>Comissão (-)</TableCell>
															<TableCell align="right">
																<Box display="flex" justifyContent="space-between" alignItems="center">
																	<Typography>R$</Typography>
																	<Typography>
																		{formatDecimalValue(totalSalesRow
																			.commissionValue)}
																	</Typography>
																</Box>
															</TableCell>
															<TableCell align="right">-</TableCell>
															<TableCell align="right">
																<Box display="flex" justifyContent="space-between" alignItems="center">
																	<Typography>R$</Typography>
																	<Typography>
																		{formatDecimalValue(totalSalesRow.commissionValue
																		+ totalBonusRow.commissionValue)}
																	</Typography>
																</Box>
															</TableCell>
														</TableRow>
														<TableRow>
															<TableCell>Lucro/Prejuízo Líquido</TableCell>
															<TableCell align="right">
																<Box display="flex" justifyContent="space-between" alignItems="center">
																	<Typography>R$</Typography>
																	<Typography>
																		{formatDecimalValue(totalSalesRow.grossProfitSaleOrder
																		- totalSalesRow.commissionValue)}
																	</Typography>
																</Box>
															</TableCell>
															<TableCell align="right">-</TableCell>
															<TableCell align="right">
																<Box display="flex" justifyContent="space-between" alignItems="center">
																	<Typography>R$</Typography>
																	<Typography>
																		{formatDecimalValue(((totalSalesRow
																			.totalWithDiscount)
																			- (totalSalesRow.discountValue)
																			- (totalSalesRow.totalDirectCost
																			+ totalBonusRow.totalDirectCost))
																			- (totalSalesRow.commissionValue))}
																	</Typography>
																</Box>
															</TableCell>
														</TableRow>
													</TableBody>
												</Table>
											</TableContainer>
										</Grid>
										<Grid item xs={2}>
											<Typography variant="h6" gutterBottom>
												Simulação
											</Typography>
											<TableContainer component={Paper}>
												<Table
													size="small"
													stickyHeader
													sx={{
														'& .MuiTypography-root': {
															fontSize: '14px',
														},
													}}
												>
													<TableHead>
														<TableRow>
															<TableCell align="left">
																<strong>Total</strong>
															</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														<TableRow>
															<TableCell align="left">
																<Box display="flex" justifyContent="space-between" alignItems="center">
																	<Typography>R$</Typography>
																	<Typography>
																		{formatDecimalValue(totalSalesRow
																			.totalWithDiscount)}
																	</Typography>
																</Box>
															</TableCell>
														</TableRow>
														<TableRow>
															<TableCell align="left">
																<Box display="flex" justifyContent="space-between" alignItems="center">
																	<Typography>R$</Typography>
																	<Typography>
																		{formatDecimalValue(totalSalesRow
																			.discountValue)}
																	</Typography>
																</Box>
															</TableCell>
														</TableRow>
														<TableRow>
															<TableCell align="left">
																<Box display="flex" justifyContent="space-between" alignItems="center">
																	<Typography>R$</Typography>
																	<Typography>
																		{formatDecimalValue(totalSalesRow
																			.totalDirectCost + totalBonusRow.totalDirectCost)}
																	</Typography>
																</Box>
															</TableCell>
														</TableRow>
														<TableRow>
															<TableCell align="left">
																<Box display="flex" justifyContent="space-between" alignItems="center">
																	<Typography>R$</Typography>
																	<Typography>
																		{formatDecimalValue((totalSalesRow
																			.totalWithDiscount)
																			- (totalSalesRow.discountValue)
																			- (totalSalesRow.totalDirectCost
																			+ totalBonusRow.totalDirectCost))}
																	</Typography>
																</Box>
															</TableCell>
														</TableRow>
														<TableRow>
															<TableCell align="left">
																<Box display="flex" justifyContent="space-between" alignItems="center">
																	<Typography>R$</Typography>
																	<Typography>
																		{formatDecimalValue(totalSalesRow
																			.commissionValue)}
																	</Typography>
																</Box>
															</TableCell>
														</TableRow>
														<TableRow>
															<TableCell align="left">
																<Box display="flex" justifyContent="space-between" alignItems="center">
																	<Typography>R$</Typography>
																	<Typography>
																		{formatDecimalValue(totalSalesRow
																			.grossProfitSimulation - totalSalesRow.commissionValue)}
																	</Typography>
																</Box>
															</TableCell>
														</TableRow>
													</TableBody>
												</Table>
											</TableContainer>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
					<Box style={{ height: 400, width: '100%', marginTop: '20px' }}>
						<DataGrid
							rows={rows}
							columns={profitabilityAnalysisFormColumns}
							onSelectionModelChange={handleRowSelection}
							getRowClassName={(params) => {
								if (params.id === 'totalSales' || params.id === 'totalBonus') {
									return 'bold-row';
								}
								return '';
							}}
							sx={{
								'& .bold-row': {
									fontWeight: 'bold',
								},
							}}
						/>
					</Box>
					{simulationDrawerMemo}
				</Box>
			</Form>
		</FormikContext.Provider>
	);
};

ProfitabilityAnalysisForm.defaultProps = {
	saleOrderSimulation: undefined,
};

export default ProfitabilityAnalysisForm;
