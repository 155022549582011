import React, { useMemo } from 'react';
import {
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Box,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { initialSaleOrderSimulationItem, initialSalePriceFormationAnalysis, ISaleOrderSimulation } from '../../../containers/ProfitabilityAnalysis/ProfitabilityAnalysisAssets';
import { currencyBRLMask } from '../../../helpers/intl';
import {
	calculateUnitValueAverageTerm,
	getSalePriceFormation,
	getSelectedItem,
	getSelectedItemIndex,
} from '../../../helpers/ProfitabilityAnalysisCalculations';
import { useFormikValuesUpdater } from '../../../hooks/useFormikValuesUpdater';

const AveragePaymentTermSimulationTable = (): JSX.Element => {
	const { values } = useFormikContext<ISaleOrderSimulation>();

	const selectedItem = useMemo(
		() => getSelectedItem(values.saleOrderSimulationItems, initialSaleOrderSimulationItem),
		[values.saleOrderSimulationItems],
	);

	const selectedItemIndex = useMemo(
		() => getSelectedItemIndex(values.saleOrderSimulationItems),
		[values.saleOrderSimulationItems],
	);

	const salePriceFormation = useMemo(
		() => getSalePriceFormation(selectedItem, initialSalePriceFormationAnalysis),
		[selectedItem],
	);

	const calculateAndSetFieldValues = useMemo(() => {
		if (!selectedItem || !selectedItem.salePriceFormation || !values.averagePaymentTerm) return {};

		if (!salePriceFormation.averagePaymentTermSalePriceFormations) return {};

		const selectedTerm = salePriceFormation.averagePaymentTermSalePriceFormations.find(
			(term: any) => term.averagePaymentTerm?.description === values.averagePaymentTerm,
		);

		if (selectedTerm) {
			const unitValueAverageTerm = calculateUnitValueAverageTerm(salePriceFormation, selectedTerm);
			return {
				[`saleOrderSimulationItems[${selectedItemIndex}].salePriceFormation.unitValueAverageTerm`]: unitValueAverageTerm,
			};
		}

		return {};
	}, [selectedItem, values.averagePaymentTerm, salePriceFormation, selectedItemIndex]);

	const options = useMemo(() => {
		if (!selectedItem || !salePriceFormation.averagePaymentTermSalePriceFormations) return [];

		return salePriceFormation.averagePaymentTermSalePriceFormations
			.map((term: any) => {
				const unitValueAverageTerm = calculateUnitValueAverageTerm(
					selectedItem.salePriceFormation,
					term,
				);

				return {
					id: term.averagePaymentTerm?.code.toString(),
					value: term.averagePaymentTerm?.code.toString(),
					label: term.averagePaymentTerm?.description,
					interestRate: term.interestRate.toFixed(3),
					unitValueAverageTerm,
				};
			});
	}, [salePriceFormation.averagePaymentTermSalePriceFormations, selectedItem]);

	useFormikValuesUpdater(
		calculateAndSetFieldValues,
		[calculateAndSetFieldValues],
	);

	if (!selectedItem || !selectedItem.salePriceFormation) {
		return <Typography variant="body2">Selecione um item para visualizar a simulação.</Typography>;
	}

	return (
		<Box sx={{ display: 'flex', position: 'relative' }}>
			<TableContainer component={Paper} sx={{ flexGrow: 1 }}>
				<Table size="small" stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell sx={{ width: '20%' }}>
								<strong>Descrição</strong>
							</TableCell>
							<TableCell align="right" sx={{ width: '20%' }}>
								<strong>Taxa de Juros (%)</strong>
							</TableCell>
							<TableCell align="right" sx={{ width: '20%' }}>
								<strong>R$ Unitário (FPV.pm.V)</strong>
							</TableCell>
							<TableCell sx={{ width: '20%', backgroundColor: '#f0f0f0' }} />
							<TableCell sx={{ width: '20%', backgroundColor: '#f0f0f0' }} />
							<TableCell sx={{ width: '10%', backgroundColor: '#f0f0f0' }} />
						</TableRow>
					</TableHead>
					<TableBody>
						{options.map((option: any) => (
							<TableRow key={option.id}>
								<TableCell
									sx={{
										fontWeight: option.label === values.averagePaymentTerm ? 'bold' : 'normal',
									}}
								>
									{option.label}
								</TableCell>
								<TableCell
									align="right"
									sx={{
										fontWeight: option.label === values.averagePaymentTerm ? 'bold' : 'normal',
									}}
								>
									{option.interestRate}
								</TableCell>
								<TableCell
									align="right"
									sx={{
										fontWeight: option.label === values.averagePaymentTerm ? 'bold' : 'normal',
									}}
								>
									{currencyBRLMask(option.unitValueAverageTerm, { decimalPlaces: 3 })}
								</TableCell>
								<TableCell sx={{ backgroundColor: '#f0f0f0' }} />
								<TableCell sx={{ backgroundColor: '#f0f0f0' }} />
								<TableCell sx={{ backgroundColor: '#f0f0f0' }} />
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};

export default AveragePaymentTermSimulationTable;
