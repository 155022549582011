import React, {
	Dispatch,
	Reducer,
	useMemo,
	useReducer,
} from 'react';
import {
	OptionsObject,
	SnackbarKey,
	SnackbarMessage,
	useSnackbar,
} from 'notistack';
import { AxiosError } from 'axios';
import { EditProductFormProps, ILocationWithPagination } from './LocationAssets';
import LocationPresentational from '../../components/Location/Location';
import {
	deleteLocationProduct,
	getLocations,
	saveLocationProduct,
	updateLocationProduct,
} from '../../services/location';
import { ProductQueryParams } from '../../interfaces/ProductQueryParams';
import { getProducts } from '../../services/product';
import { IProduct } from '../Product/ProductAssets';
import { LocationProductQueryParams } from '../../interfaces/LocationProduct';

enum ActionType {
	LOADING,
	LOCATION,
	PRODUCT,
	LOCATIONS_FILTER,
}

interface IState {
	loading: boolean;
	location: ILocationWithPagination;
	products: IProduct[];
	locationsFilter: ILocationWithPagination;
}

type TAction =
	| { type: ActionType.LOADING; payload: { loading: boolean } }
	| { type: ActionType.PRODUCT; payload: { products: IProduct[] } }
	| { type: ActionType.LOCATION; payload: { location: ILocationWithPagination } }
	| { type: ActionType.LOCATIONS_FILTER; payload: { locationsFilter: ILocationWithPagination } };

interface ILocationActions {
    setLoading(loading: boolean): void;
	getLocations: (queryParams?: LocationProductQueryParams) => void;
	getAllLocations: (queryParams?: LocationProductQueryParams) => void;
	updateLocationProduct(
		productId: string,
		locationId: string,
        values: EditProductFormProps,
	): Promise<void>;
	saveLocationProduct(
		productId: string,
		locationId: string,
        valuedQuantity: string
	): Promise<void>;
	getProducts(queryParams: ProductQueryParams): void;
	deleteLocationProduct(productId: string, locationId: string): void;
}

const initialState: IState = {
	loading: false,
	location: { count: 0, locations: [] },
	products: [],
	locationsFilter: { count: 0, locations: [] },
};

const reducer: Reducer<IState, TAction> = (state, action) => {
	switch (action.type) {
		case ActionType.LOADING:
			return { ...state, loading: action.payload.loading };
		case ActionType.PRODUCT:
			return { ...state, products: action.payload.products };
		case ActionType.LOCATION:
			return {
				...state,
				location: action.payload.location,
			};
		case ActionType.LOCATIONS_FILTER:
			return {
				...state,
				locationsFilter: action.payload.locationsFilter,
			};
		default:
			throw new Error();
	}
};

const LocationActions = (
	dispatch: Dispatch<TAction>,
	enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
): ILocationActions => {
	const actions = {
		setLoading(loading: boolean) {
			dispatch({ type: ActionType.LOADING, payload: { loading } });
		},
		getLocations(queryParams?: LocationProductQueryParams) {
			actions.setLoading(true);
			const take = queryParams?.take ?? 10;

			const dataParams = {
				take,
				orderBy: 'asc',
				skip: queryParams?.skip ? (queryParams.skip - 1) * take : 0,
				currentQuantity: queryParams?.currentQuantity ? queryParams?.currentQuantity : undefined,
				locationValue: queryParams?.locationValue ? queryParams.locationValue : undefined,
				productIds: queryParams?.productIds?.length ? queryParams.productIds.join(',') : undefined,
				available: queryParams?.available !== undefined && (queryParams?.available !== '' as any) ? queryParams?.available : undefined,
			};

			getLocations(dataParams as LocationProductQueryParams)
				.then((response) => {
					dispatch({
						type: ActionType.LOCATION,
						payload: {
							location: response.data,
						},
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
						{ variant: 'error' },
					);
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		getAllLocations(queryParams?: LocationProductQueryParams) {
			actions.setLoading(true);
			const dataParams: LocationProductQueryParams = {
				orderBy: 'desc',
				skip: 0,
			};

			getLocations(dataParams)
				.then((response) => {
					dispatch({
						type: ActionType.LOCATIONS_FILTER,
						payload: {
							locationsFilter: response.data,
						},
					});
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(
						error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
						{ variant: 'error' },
					);
				})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		async updateLocationProduct(
			productId: string,
			locationId: string,
			values: EditProductFormProps,
		) {
			actions.setLoading(true);

			try {
				const result = await updateLocationProduct(productId, locationId, values);

				enqueueSnackbar(
					result.data.message || 'Produto atualizado com sucesso.',
					{ variant: 'success' },
				);
			} catch (e) {
				const error = e as AxiosError;
				enqueueSnackbar(
					error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
					{ variant: 'error' },
				);
			} finally {
				actions.setLoading(false);
			}
		},
		async saveLocationProduct(
			productId: string,
			locationId: string,
			valuedQuantity: string,
		) {
			actions.setLoading(true);
			try {
				const result = await saveLocationProduct(productId, locationId, valuedQuantity);
				enqueueSnackbar(
					result.data.message || 'Produto atualizado com sucesso.',
					{ variant: 'success' },
				);
			} catch (e) {
				const error = e as AxiosError;
				enqueueSnackbar(
					error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
					{ variant: 'error' },
				);
			} finally {
				actions.setLoading(false);
			}
		},
		getProducts(queryParams: ProductQueryParams) {
			actions.setLoading(true);
			getProducts(queryParams).then((response) => {
				dispatch({
					type: ActionType.PRODUCT,
					payload: {
						products: response.data.data,
					},
				});
			}).catch((error: AxiosError) => {
				enqueueSnackbar(
					error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.',
					{ variant: 'error' },
				);
			})
				.finally(() => {
					actions.setLoading(false);
				});
		},
		deleteLocationProduct(productId: string, locationId: string) {
			actions.setLoading(true);
			deleteLocationProduct(productId, locationId)
				.then((response) => {
					enqueueSnackbar(response?.data.message, { variant: 'success' });
				})
				.catch((error: AxiosError) => {
					enqueueSnackbar(error.response?.data.message || 'Algum erro ocorreu, tente novamente ou contate um administrador.', {
						variant: 'error',
					});
					actions.setLoading(false);
				});
		},
	};

	return actions;
};

const Location = ():JSX.Element => {
	const [state, dispatch] = useReducer<Reducer<IState, TAction>>(
		reducer,
		initialState,
	);
	const { enqueueSnackbar } = useSnackbar();
	const actions = useMemo(
		() => LocationActions(dispatch, enqueueSnackbar),
		[dispatch, enqueueSnackbar],
	);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return (<LocationPresentational {...state} {...actions} />);
};

export default Location;
