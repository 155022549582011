import React, { useCallback, useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import {
	Form, FormikContext, useFormik,
} from 'formik';
import Input from '../Common/Form/Input';
import Autocomplete from '../Common/Form/Autocomplete';
import { IProduct } from '../../containers/Product/ProductAssets';
import { LocationProductQueryParams } from '../../interfaces/LocationProduct';
import { ProductQueryParams } from '../../interfaces/ProductQueryParams';

interface InventoryStartFilterProps {
    sendFilter: (params: Partial<LocationProductQueryParams>) => void;
	products: IProduct[];
	loading: boolean;
	initialValues: Partial<LocationProductQueryParams>;
	setSelectedProducts: (products: IProduct[]) => void;
	selectedProducts: IProduct[];
	getProducts: (queryParams?: ProductQueryParams) => void;
}

const InventoryStartFilter = ({
	sendFilter,
	loading,
	initialValues,
	products,
	setSelectedProducts,
	selectedProducts,
	getProducts,
}: InventoryStartFilterProps): JSX.Element => {
	const [productOptions, setProductOptions] = useState<IProduct[]>(products);

	const onReset = useCallback((resetForm) => {
		sendFilter({});
		resetForm();
	}, [sendFilter]);

	const formik = useFormik({
		initialValues,
		onSubmit: sendFilter,
	});

	useEffect(() => {
		const updatedOptions = [...selectedProducts,
			...products.filter((p) => !selectedProducts.some((sp) => sp.id === p.id))];
		setProductOptions(updatedOptions);
	}, [products, selectedProducts]);

	const handleChangeProducts = useCallback((__event, newValue, reason) => {
		if (reason === 'selectOption' && newValue !== null) {
			const productToAdd = products.find((product) => newValue.includes(product.id));
			if (productToAdd) {
				setSelectedProducts([...selectedProducts, productToAdd]);
			}
			formik.setFieldValue('productIds', newValue);
		} else if (reason === 'input') {
			getProducts({ description: newValue, skip: 0 });
		} else if (reason === 'removeOption' || reason === 'clear') {
			formik.setFieldValue('productIds', newValue);
		}
	}, [formik, getProducts, products, selectedProducts, setSelectedProducts]);

	return (
		<FormikContext.Provider value={formik}>
			<Form>
				<Grid container spacing={2} sx={{ p: 2, marginTop: 6 }}>

					<Grid item xs={12}>
						<Input.InputField
							id="locationValue"
							name="locationValue"
							label="Localização"
							autoComplete="off"
							fullWidth
						/>
					</Grid>

					<Grid item xs={12}>
						<Autocomplete
							label="Produtos"
							name="productIds"
							valueKey="id"
							valueLabel="code"
							labelKey="description"
							options={productOptions || []}
							loading={loading}
							onInputChange={(
								event,
								newValue,
								reason,
							) => {
								if (newValue.length > 2) {
									handleChangeProducts(event, newValue, reason);
								}
							}}
							onChange={(event, newValue, reason) => handleChangeProducts(event, newValue, reason)}
							multiple
							fullWidth
						/>
					</Grid>

					<Grid
						item
						xs={12}
						sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}
					>
						<Button variant="outlined" onClick={() => onReset(formik.handleReset)}>
							Restaurar
						</Button>
						<Button variant="contained" type="submit">
							Filtrar
						</Button>
					</Grid>
				</Grid>
			</Form>
		</FormikContext.Provider>
	);
};

export default InventoryStartFilter;
