import React, { useCallback, useEffect, useRef } from 'react';
import {
	Box,
	Button,
	Grid,
	IconButton,
	Stack,
	Typography,
} from '@mui/material';
import {
	Form,
	FormikContext,
	useFormik,
} from 'formik';
import {
	useLocation,
	useNavigate,
} from 'react-router-dom';
import { Add, Remove } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import Input from '../../../Common/Form/Input';
import {
	SaveTaskType,
	ValidateInventoryParams,
} from '../../../../containers/Mobile/Inventory/InventoryAssets';
import { Loading } from '../../../Common/Loading';
import { formatBarCode } from '../../../../helpers/masks';
import { InventoryTaskDetails } from '../../../../containers/Inventory/InventoryAssets';

interface InventoryFinishProps {
	loading: boolean;
	inventoryTask: InventoryTaskDetails;
	saveTaskCount(task: SaveTaskType): void;
	getInventoryById(params: ValidateInventoryParams): void;
	countNumber: number;
}

const InventoryFinish = ({
	loading,
	inventoryTask,
	saveTaskCount,
	getInventoryById,
	countNumber,
}: InventoryFinishProps): JSX.Element => {
	const location = useLocation();
	const params: ValidateInventoryParams = location.state;
	const inputRef = useRef<HTMLInputElement>(null);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const formik = useFormik({
		initialValues: {
			productBarCode: '',
			productQuantity: 0,
		},
		onSubmit: (values) => {
			if (values.productQuantity >= 0) {
				saveTaskCount({
					quantity: values.productQuantity,
					taskId: inventoryTask.id,
				});
			} else {
				enqueueSnackbar('Quantidade inválida!', {
					variant: 'error',
				});
			}
		},
	});

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, [params, inventoryTask, loading]);

	const handleIncrement = useCallback(():void => {
		formik.setFieldValue('productQuantity', (formik.values.productQuantity += 1));
	}, [formik]);

	const handleDecrement = useCallback(():void => {
		formik.setFieldValue('productQuantity', (formik.values.productQuantity -= 1));
	}, [formik]);

	useEffect(() => {
		if (params.reader && formik.values.productQuantity === 0) {
			handleIncrement();
		}
		getInventoryById({
			barCodeLocation: params.barCodeLocation,
			barCodeProduct: params.barCodeProduct,
			inventoryId: params.inventoryId,
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params]);

	const handleBlurOrEnter = useCallback((event: React.KeyboardEvent<HTMLInputElement>): void => {
		const target = event.target as HTMLInputElement;
		if ((event.key === 'Tab') || (event.key === 'Enter')) {
			event.preventDefault();
			if (target.value === params.barCodeProduct) {
				handleIncrement();
			} else {
				enqueueSnackbar('Código de barras inválido!', {
					variant: 'error',
				});
			}
			formik.setFieldValue('productBarCode', '');
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading) {
		return (
			<Box padding={1}>
				<Loading />
			</Box>
		);
	}

	if (!inventoryTask.id) {
		return (
			<Box padding={1}>
				<Typography sx={{ my: 6 }} variant="h5" align="center">
					Nenhum item encontrado ou já foi contado.
				</Typography>
				<Button size="large" variant="contained" sx={{ my: 2, width: '100%' }} onClick={() => navigate(-1)}>
					VOLTAR
				</Button>
			</Box>
		);
	}

	return (
		<FormikContext.Provider value={formik}>
			<Form>
				<Box padding={1}>
					<Typography variant="caption" textTransform="uppercase">{`${countNumber}ª Contagem`}</Typography>
					<Typography
						textAlign="center"
					>
						{`${formatBarCode(inventoryTask.locationOrigin.barCode)}`}
					</Typography>
					<Box
						sx={{
							padding: 2, border: 1, borderColor: '#bbb', bgcolor: 'white', borderRadius: 2, mb: 2, display: 'flex', justifyContent: 'space-between',
						}}
					>
						<Stack direction="column">

							<Typography
								textAlign="center"
								variant="h6"
								sx={{ marginBottom: 3 }}
							>
								{`${inventoryTask.product.barCode} - ${inventoryTask.product.description}`}
							</Typography>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6}>
									<Input.InputField
										label="Código de Barras"
										id="productBarCode"
										name="productBarCode"
										autoComplete="off"
										onKeyDown={(
											event: React.KeyboardEvent<HTMLInputElement>,
										) => handleBlurOrEnter(event)}
										inputRef={inputRef}
										fullWidth
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Box
										sx={{
											width: '100%',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										<IconButton
											size="large"
											onClick={handleDecrement}
											disabled={formik.values.productQuantity === 0}
										>
											<Remove />
										</IconButton>

										<Input.InputField
											id="productQuantity"
											name="productQuantity"
											style={{ width: 100, textAlign: 'center' }}
											type="number"
											required
										/>
										<IconButton
											size="large"
											onClick={handleIncrement}
										>
											<Add />
										</IconButton>
									</Box>
								</Grid>
								<Grid item xs={12} sm={6} display="flex" justifyContent="space-between" marginTop={2} gap={2}>
									<Button
										variant="contained"
										size="small"
										color="primary"
										sx={{ width: '100%' }}
										type="submit"
										disabled={formik.values.productQuantity < 0}
									>
										Confirmar
									</Button>
								</Grid>
							</Grid>
						</Stack>
					</Box>
				</Box>
			</Form>
		</FormikContext.Provider>
	);
};

export default InventoryFinish;
