import { AxiosResponse } from 'axios';
import api from './api';
import {
	EditProductFormProps,
	ILocation, ILocationProduct, ILocationRequest,
	ILocationWithPagination,
} from '../containers/Location/LocationAssets';
import { IMessageResponse } from '../interfaces/MessageResponse';
import { LocationProductQueryParams, LocationProductResponse } from '../interfaces/LocationProduct';

export const getLocations = (queryParams?: LocationProductQueryParams): Promise<AxiosResponse<ILocationWithPagination>> => api.get<ILocationWithPagination>('/location', { params: queryParams });

export const updateLocationProduct = (
	productId: string,
	locationId: string,
	values: EditProductFormProps,
): Promise<AxiosResponse<IMessageResponse>> => api.patch<ILocationProduct, AxiosResponse<IMessageResponse>>('/location/product/', { productId, locationId, ...values });

export const getLocationById = (id: string): Promise<AxiosResponse<ILocation>> => api.get<ILocation>(`location/${id}`);

export const getLocationsProducts = (params: LocationProductQueryParams): Promise<AxiosResponse<LocationProductResponse>> => api.get<LocationProductResponse>('location/location-product', { params });

export const saveLocationProduct = (
	productId: string,
	locationId: string,
	valuedQuantity: string,
): Promise<AxiosResponse<IMessageResponse>> => api.post<ILocationProduct, AxiosResponse<IMessageResponse>>('/location/product/', {
	productId, locationId, valuedQuantity,
});

export const deleteLocationProduct = (productId: string, locationId: string): Promise<AxiosResponse<IMessageResponse>> => api.delete<IMessageResponse>(`/location/product/${productId}/${locationId}`);

export const updateLocation = (
	id: string,
	data:ILocationRequest,
): Promise<AxiosResponse<IMessageResponse>> => api.patch<ILocation, AxiosResponse<IMessageResponse>>(`/location/${id}`, data);

export const saveLocation = (
	data:ILocationRequest,
): Promise<AxiosResponse<IMessageResponse>> => api.post<ILocation, AxiosResponse<IMessageResponse>>('/location/', data);

export const deleteLocation = (id: string): Promise<AxiosResponse<IMessageResponse>> => api.delete<ILocation, AxiosResponse<IMessageResponse>>(`/location/${id}`);

export const getLocationsMain = (): Promise<AxiosResponse<ILocation[]>> => api.get<ILocation[]>('/location/main');
