import React, {
	SyntheticEvent,
	useCallback,
	useEffect,
	useState,
} from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import {
	Form,
	FormikContext,
	useFormik,
} from 'formik';
import {
	ILocationWithPagination,
	IProductMeasures,
} from '../../../containers/Location/LocationAssets';
import Autocomplete from '../../Common/Form/Autocomplete';
import { formatBarCode } from '../../../helpers/masks';
import { User } from '../../../containers/User/UserAssets';
import { UserQueryParams } from '../../../interfaces/UserQueryParams';
import { LocationProductQueryParams } from '../../../interfaces/LocationProduct';

export interface CreateInventoryTaskFormProps {
    locationId: string;
    productId: string;
	operatorId?: string;
}

interface CreateInventoryTaskModalProps {
    open: boolean;
    onClose: () => void;
    handleConfirmCreateInventoryTask: (params: CreateInventoryTaskFormProps) => void;
    loading: boolean;
    locations: ILocationWithPagination;
	startCount: boolean;
	operators: User[];
	getUsers: (queryParams: UserQueryParams) => void;
	countNumber: number;
}

const initialValues = {
	locationId: '',
	productId: '',
	operatorId: '',
};

const CreateInventoryTaskModal = ({
	open,
	loading,
	locations,
	handleConfirmCreateInventoryTask,
	onClose,
	startCount,
	operators,
	getUsers,
	countNumber,
}: CreateInventoryTaskModalProps): JSX.Element => {
	const [productsLocation, setProductsLocation] = useState<IProductMeasures[]>([]);
	const [loadingMap, setLoadingMap] = useState<boolean>(false);

	const formik = useFormik({
		initialValues,
		onSubmit: () => {
			handleConfirmCreateInventoryTask({
				locationId: formik.values.locationId,
				productId: formik.values.productId,
				...(formik.values.operatorId && { operatorId: formik.values.operatorId }),
			});
		},
	});

	useEffect(() => {
		setLoadingMap(true);
		const products = locations.locations.find((
			location,
		) => location.id === formik.values.locationId);

		if (products && products.products) {
			const productArray = products.products.map((item) => item.product);
			setProductsLocation(productArray);
		}

		setLoadingMap(false);
	}, [
		formik.values.locationId,
		locations.locations,
	]);

	const locationsWithMask = locations.locations.map((location) => ({
		...location,
		barCode: formatBarCode(location.barCode),
	}));

	return (
		<FormikContext.Provider value={formik}>
			<Dialog open={open} fullWidth>
				<Form>
					<DialogTitle>Criar Tarefa do Inventário</DialogTitle>
					<DialogContent>
						<Autocomplete
							label="Localização"
							options={locationsWithMask || []}
							name="locationId"
							labelKey="name"
							valueKey="id"
							valueLabel="barCode"
							sx={{ my: 2 }}
							loading={loading}
							closeOnSelect
							required
						/>
						<Autocomplete
							label="Produto"
							options={productsLocation}
							name="productId"
							labelKey="description"
							valueKey="id"
							valueLabel="code"
							sx={{ my: 2 }}
							loading={loadingMap}
							disabled={!formik.values.locationId}
							closeOnSelect
							required
						/>
						{((startCount && countNumber > 1) || (startCount && countNumber !== 1)) && (
							<Autocomplete
								label="Operador"
								options={operators}
								name="operatorId"
								labelKey="email"
								valueKey="id"
								valueLabel="name"
								sx={{ mb: 2 }}
								closeOnSelect
								loading={loadingMap}
								disabled={!formik.values.locationId}
								onInputChange={(_event: SyntheticEvent, value: string, reason: string) => {
									if (reason === 'clear') {
										getUsers({ skip: 0 });
									} else if (value?.length >= 1 && reason === 'input') {
										getUsers({ skip: 0, name: value });
									}
								}}
								required={startCount}
							/>
						)}
					</DialogContent>
					<DialogActions sx={{ gap: 1 }}>
						<Button onClick={onClose}>Cancelar</Button>
						<Button
							type="submit"
							variant="contained"
						>
							Confirmar
						</Button>
					</DialogActions>
				</Form>
			</Dialog>
		</FormikContext.Provider>
	);
};

export default CreateInventoryTaskModal;
