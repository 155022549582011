import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Paper, Stack, Typography } from '@mui/material';
import {
	ISaleOrderSimulation,
	ISaleOrderSimulationItem,
} from '../../containers/ProfitabilityAnalysis/ProfitabilityAnalysisAssets';
import ProfitabilityAnalysisForm from './ProfitabilityAnalysisForm';
import { Loading } from '../Common/Loading';
import { IIndirectCostSalePriceFormation } from '../../containers/SalePriceFormation/SalePriceFormationAssets';

interface ProfitabilityAnalysisEditProps {
	loading: boolean;
	saleOrderSimulation?: ISaleOrderSimulation;
	getSaleOrderSimulationById: (id: string) => void;
	updateSaleOrderSimulationItem: (
		simulationId: string,
		itemId: string,
		data: ISaleOrderSimulationItem,
	) => void;
	upsertIndirectCostToFormation(data: IIndirectCostSalePriceFormation): void;
	updateSelectedRangeSimulation(
		rangeCommissionId: string,
		salePriceFormationId: string,
		selectedRangeSimulation: boolean,
	): void;
}

const ProfitabilityAnalysisEdit = ({
	loading,
	saleOrderSimulation,
	getSaleOrderSimulationById,
	updateSaleOrderSimulationItem,
	upsertIndirectCostToFormation,
	updateSelectedRangeSimulation,
}: ProfitabilityAnalysisEditProps): JSX.Element => {
	const { id } = useParams<{ id: string }>();

	useEffect(() => {
		if (id) {
			getSaleOrderSimulationById(id);
		}
	}, [id, getSaleOrderSimulationById]);

	const handleSubmit = useCallback((itemId: string, itemData: ISaleOrderSimulationItem) => {
		const {
			grossMargin,
			netProfitMargin,
			salePriceFormation,
			codeDescription,
			selected,
			...cleanedItemData
		} = itemData;

		if (id) {
			updateSaleOrderSimulationItem(id, itemId, cleanedItemData);
		}
	}, [id, updateSaleOrderSimulationItem]);

	if (id && !saleOrderSimulation) {
		return <Loading />;
	}

	return (
		<Paper sx={{ mt: 3, p: 3 }} elevation={3}>
			<Stack spacing="1rem">
				<Typography variant="h4" display="block" color="primary" gutterBottom>
					Editar Análise de Rentabilidade
				</Typography>
				<ProfitabilityAnalysisForm
					loading={loading}
					saleOrderSimulation={saleOrderSimulation}
					upsertIndirectCostToFormation={upsertIndirectCostToFormation}
					updateSelectedRangeSimulation={updateSelectedRangeSimulation}
					onSubmit={handleSubmit}
				/>
			</Stack>
		</Paper>
	);
};

ProfitabilityAnalysisEdit.defaultProps = {
	saleOrderSimulation: undefined,
};

export default ProfitabilityAnalysisEdit;
